import React, { useState } from 'react';
import axios from 'axios';
import './ProfessionalForm.css'
const ProfessionalForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    profession: '',
    address: '',
    email: '',
    lga: '',
    state: '',
    tags: [],
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTagChange = (e, index) => {
    const newTags = [...formData.tags];
    newTags[index] = e.target.value;
    setFormData({ ...formData, tags: newTags });
  };

  const handleAddTag = () => {
    setFormData({ ...formData, tags: [...formData.tags, ''] });
  };

  const handleRemoveTag = (index) => {
    const newTags = [...formData.tags];
    newTags.splice(index, 1);
    setFormData({ ...formData, tags: newTags });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://havendogs.onrender.com/api/professionals', formData);
      console.log(response.data); // Handle success response
    } catch (err) {
      console.error(err); // Handle error
    }
  };


  return (
    <form onSubmit={handleSubmit} className="professional-form">
  <div className="form-group">
    <label htmlFor="firstName" className="form-label">First Name:</label>
    <input
      type="text"
      id="firstName"
      name="firstName"
      value={formData.firstName}
      onChange={handleChange}
      required
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label htmlFor="lastName" className="form-label">Last Name:</label>
    <input
      type="text"
      id="lastName"
      name="lastName"
      value={formData.lastName}
      onChange={handleChange}
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label htmlFor="profession" className="form-label">Profession:</label>
    <input
      type="text"
      id="profession"
      name="profession"
      value={formData.profession}
      onChange={handleChange}
      required
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label htmlFor="address" className="form-label">Address:</label>
    <input
      type="text"
      id="address"
      name="address"
      value={formData.address}
      onChange={handleChange}
      required
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label htmlFor="email" className="form-label">Email:</label>
    <input
      type="email"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
      required
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label htmlFor="lga" className="form-label">LGA:</label>
    <input
      type="text"
      id="lga"
      name="lga"
      value={formData.lga}
      onChange={handleChange}
      required
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label htmlFor="state" className="form-label">State:</label>
    <input
      type="text"
      id="state"
      name="state"
      value={formData.state}
      onChange={handleChange}
      required
      className="form-input"
    />
  </div>

  <div className="form-group">
    <label className="form-label">Tags:</label>
    <div className="tag-input-container">
      {formData.tags.map((tag, index) => (
        <div key={index} className="tag-input-wrapper">
          <input
            type="text"
            name="tags"
            value={tag}
            onChange={(e) => handleTagChange(e, index)}
            className="form-input tag-input"
          />
          <button
            type="button"
            onClick={() => handleRemoveTag(index)}
            className="remove-tag-btn"
          >
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddTag} className="add-tag-btn">
        Add Tag
      </button>
    </div>
  </div>

  <button type="submit" className="submit-btn">Create Professional</button>
</form>
  );
};

export default ProfessionalForm;