import React, { useState, useEffect } from 'react';
import './Innerpages.css'

// FAQItem component
const FAQItem = ({ question, answer }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <li className={`cs-faq-item ${isActive ? 'active' : ''}`} onClick={handleClick}>
      <button className="cs-button">
        <span className="cs-button-text">
          {question}
        </span>
      </button>
      <p className="cs-item-p">
        {answer}
      </p>
    </li>
  );
};

function Faq() {

  const filtersSelector = '.cs-option';
  const FAQselector = '.cs-faq-group';
  const activeClass = 'cs-active';
  const hiddenClass = 'cs-hidden';

  const [activeFilter, setActiveFilter] = useState('all');
  const [filters, setFilters] = useState([]);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const $filters = document.querySelectorAll(filtersSelector);
    const $faqs = document.querySelectorAll(FAQselector);

    setFilters($filters);
    setFaqs($faqs);

    if ($filters.length > 0) {
      $filters[0].classList.add(activeClass);
    }

    $filters.forEach($filter => {
      $filter.addEventListener('click', () => handleFilterClick($filter));
    });

    return () => {
      $filters.forEach($filter => {
        $filter.removeEventListener('click', () => handleFilterClick($filter));
      });
    };
  }, []);

  const handleFilterClick = ($filter) => {
    const filter = $filter.dataset.filter;
    setActiveFilter(filter);

    filters.forEach($f => $f.classList.remove(activeClass));
    $filter.classList.add(activeClass);
  };

  useEffect(() => {
    const showAll = activeFilter === 'all';
    faqs.forEach($faq => {
      const show = showAll || $faq.dataset.category === activeFilter;
      $faq.classList.toggle(hiddenClass, !show);
    });
  }, [activeFilter, faqs]);



  return (
    <div>
    <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">FAQ</span>
        </div>
        {/* Background Image */}
        <picture className="cs-background">
          {/* Mobile Image */}
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          {/* Tablet and above Image */}
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>



      <section id="faq-1720">
    <div className="cs-container">
        <div className="cs-content">
            <span className="cs-topper">Faq</span>
            <h2 className="cs-title">Frequently Asked Questions</h2>
        </div>
        <div className="cs-flex-group">
            <div className="cs-button-group">
                <div className="cs-flex">
                    <button className="cs-option" data-filter="one">
                        <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/plus.svg" alt="plus graphic" width="12" height="12" decoding="async" aria-hidden="true"/>
                        Pet Adoption Services
                    </button>
                    <button className="cs-option" data-filter="two">
                        <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/plus.svg" alt="plus graphic" width="12" height="12" decoding="async" aria-hidden="true"/>
                        Pet Walking and Training
                    </button>
                    <button className="cs-option" data-filter="three">
                        <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/plus.svg" alt="plus graphic" width="12" height="12" decoding="async" aria-hidden="true"/>
                        Veterinary Services
                    </button>
                    <button className="cs-option" data-filter="four">
                        <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/plus.svg" alt="plus graphic" width="12" height="12" decoding="async" aria-hidden="true"/>
                        Pet Recommendations
                    </button>
                    <button className="cs-option" data-filter="five">
                        <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/plus.svg" alt="plus graphic" width="12" height="12" decoding="async" aria-hidden="true"/>
                        Volunteer Opportunities
                    </button>
                </div>
                <picture className="cs-picture">
                    <source media="(max-width: 600px)" srcSet="https://www.pexels.com/photo/interracial-hands-with-pens-on-white-paper-with-graph-7706933/"/>
                    <source media="(min-width: 601px)" srcSet="https://www.pexels.com/photo/interracial-hands-with-pens-on-white-paper-with-graph-7706933/"/>
                    <img loading="lazy" decoding="async" src="https://www.pexels.com/photo/interracial-hands-with-pens-on-white-paper-with-graph-7706933/" alt="people" width="353" height="493"/>
                </picture>
            </div>
            <div className="cs-wrapper">
                <ul className="cs-faq-group" data-category="one">
        <FAQItem
          question="How can I adopt a pet from Haven Pet Home and Animal Care Foundation?"
          answer="At Haven Pet Home, we have a thorough adoption process to ensure that each pet finds a loving and responsible forever home. You can visit our website or shelter to view available pets and submit an adoption application. Our staff will guide you through the process and help match you with the perfect companion."
        />
        <FAQItem
          question="What documents are required for pet adoption?"
          answer="To adopt a pet, you will typically need to provide a valid government-issued ID, proof of address, and potentially a pet rental agreement if applicable. Our staff can provide you with a complete list of requirements during the adoption process."
        />
                </ul>
                <ul className="cs-faq-group cs-hidden" data-category="two"> <FAQItem
          question="Does Haven Pet Home offer pet walking and training services?"
          answer="Yes, we offer pet walking and training services to our community members. Our experienced team of trainers can help with basic obedience, behavior modification, and socialization for dogs and cats. Contact us for more information and pricing."
        />
        <FAQItem
          question="How often can I schedule pet walking services?"
          answer="We offer flexible pet walking schedules to accommodate your needs. You can schedule daily, weekly, or even occasional walks for your furry friend. Our team will ensure your pet gets the necessary exercise and attention while you're away."
        />
        </ul>
                <ul className="cs-faq-group cs-hidden" data-category="three">
                <FAQItem
          question="Does Haven Pet Home have a veterinary clinic?"
          answer="Yes, we have an on-site veterinary clinic staffed by licensed veterinarians and veterinary technicians. Our clinic provides a range of services, including routine check-ups, vaccinations, dental care, and basic surgeries for pets adopted from our shelter."
        />
        <FAQItem
          question="Can I bring my pet for veterinary services if I didn't adopt from Haven Pet Home?"
          answer="Absolutely! Our veterinary clinic is open to the public, and we welcome pets from all families in our community. We offer competitive pricing and high-quality care for your beloved companions."
        />
                </ul>
                <ul className="cs-faq-group cs-hidden" data-category="four">
                <FAQItem
          question="How can I get pet recommendations from Haven Pet Home?"
          answer="Our team of experts is happy to provide personalized pet recommendations based on your lifestyle, living situation, and preferences. We can guide you in selecting the right breed, size, and temperament to ensure a successful match between you and your new furry friend."
        />
        <FAQItem
          question="Do you offer resources for new pet owners?"
          answer="Absolutely! We understand that bringing a new pet into your life can be challenging, so we offer comprehensive resources, including pet care guides, training tips, and access to our team of experts for any questions or concerns you may have along the way."
        />
        </ul>
                <ul className="cs-faq-group cs-hidden" data-category="three">
                <FAQItem
          question="What veterinary services are included in the adoption fee?"
          answer="Adoption of an animal is completely FREE! When you adopt a pet from Haven Pet Home, we do a background check on you as we care about our animals having the best home possible. Our veterinary team ensures that each pet is healthy and ready to join their new family."
        />
        <FAQItem
          question="Can I schedule appointments for my pet at the veterinary clinic?"
          answer="Yes, you can schedule appointments for your pet at our veterinary clinic. We recommend calling ahead or booking online to ensure availability and secure a convenient time slot for your visit."
        />
                </ul>
                <ul className="cs-faq-group cs-hidden" data-category="five"> <FAQItem
          question="How can I become a volunteer at Haven Pet Home?"
          answer="We are always grateful for the support of dedicated volunteers! You can visit our website or stop by our shelter to learn about various volunteer opportunities, such as assisting with animal care, helping at adoption events, or providing administrative support. Every contribution makes a difference."
        />
        <FAQItem
          question="What kind of training is provided for volunteers?"
          answer="At Haven Pet Home, we prioritize the safety and well-being of our animals and volunteers. All volunteers will receive comprehensive training specific to their role, covering topics like proper animal handling, cleaning protocols, and safety guidelines. Our team will ensure you feel confident and prepared."
        />
        </ul>
                <ul className="cs-faq-group cs-hidden" data-category="three">
                <FAQItem
          question="Do you offer emergency veterinary services?"
          answer="While our veterinary clinic does not provide 24/7 emergency services, we have made arrangements for vets to work remotely after-hours pending the coming of work-hours. Our staff can provide you with recommendations and contact information for these facilities should your pet require urgent medical attention."
        />
        <FAQItem
          question="Can I purchase pet medications and supplies at the veterinary clinic?"
          answer="Yes, our veterinary clinic has a well-stocked inventory of pet medications, preventatives, and supplies. You can purchase these items during your pet's appointment or visit our clinic's retail section for your convenience."
        /></ul>

<ul className="cs-faq-group cs-hidden" data-category="five">
  <FAQItem
    question="How can I volunteer to walk dogs at Haven Pet Home?"
    answer="We always appreciate volunteers who can help provide exercise and care for our shelter dogs. You can sign up for dog walking shifts on our website or by contacting our volunteer coordinator. Basic training on proper handling and safety procedures will be provided."
  />
  <FAQItem
    question="What other volunteer opportunities are available?"
    answer="There are many ways you can get involved as a volunteer at Haven Pet Home. Some opportunities include assisting with animal socialization, helping at adoption events, providing administrative support, or even fostering pets temporarily. We're grateful for any time you can contribute."
  />
</ul>
<ul className="cs-faq-group cs-hidden" data-category="three">
  <FAQItem
    question="Do you offer affordable veterinary care for low-income pet owners?"
    answer="Yes, as part of our mission to make pet care accessible to all, we offer discounted veterinary services for families facing financial hardship. Please inquire about our income-based pricing and payment plans when scheduling an appointment at our clinic."
  />
  <FAQItem
    question="What services does the veterinary clinic provide?"
    answer="Our full-service veterinary clinic offers routine check-ups, vaccinations, dental care, spaying/neutering, microchipping, and basic surgeries. We also have diagnostic capabilities like x-rays and laboratory testing to ensure comprehensive care for your pets."
  />
</ul>
            </div>
        </div>
    </div>
</section>
                                
      </div>
  )
}

export default Faq