import React, { useState } from "react";
import { ConfigProvider, Button, theme, Card } from "antd";
import OverallTab from "components/widgets/OverallTab";
import Footer from "./Footer";

const HomePage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { defaultAlgorithm, darkAlgorithm } = theme;

  const handleClick = () => {
    setIsDarkMode((previousValue) => !previousValue);
  };
  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
    >
    
   
      <>
        <Button onClick={handleClick}>
          {isDarkMode ? "Light" : "Dark"} Theme
        </Button>
        
        <OverallTab isDarkMode={isDarkMode} />
      
      </>


    </ConfigProvider>
  );
};

export default HomePage;
