import React, { useState } from 'react';
import Ticket from './Ticket';
import { ProfessionalsComponent, PostsComponent, UsersComponent } from './AllData';
import { StatsCard } from './AllData';
import Divider from '@mui/material/Divider';
import ProfessionalForm from './ProfessionalForm';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Dasboard() {
  const [accordionExpanded, setAccordionExpanded] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : null);
  };

  return (
    <div style={{ padding: '0 30px', paddingTop:'12%', paddingBottom:'10%' }}>
      <Accordion expanded={accordionExpanded === 'panel0'} onChange={handleAccordionChange('panel0')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Stats Card
        </AccordionSummary>
        <AccordionDetails>
          <StatsCard />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={accordionExpanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Ticket
        </AccordionSummary>
        <AccordionDetails>
          <Ticket />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={accordionExpanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Add A professional
        </AccordionSummary>
        <AccordionDetails>
          <ProfessionalForm />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={accordionExpanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Professionals Component
        </AccordionSummary>
        <AccordionDetails>
          <ProfessionalsComponent />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={accordionExpanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Posts Component
        </AccordionSummary>
        <AccordionDetails>
          <PostsComponent />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={accordionExpanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Users Component
        </AccordionSummary>
        <AccordionDetails>
          <UsersComponent />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Dasboard;