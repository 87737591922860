// AnimalDetails.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Select, MenuItem  } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import { storage } from '../../firebase';
import './AnimalDetails.css';
const AnimalDetails = () => {
  const { id } = useParams();
  const [animal, setAnimal] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedAnimal, setEditedAnimal] = useState({}); // Initialize editedAnimal with an empty object

  const fetchAnimalDetails = async () => {
    try {
      const response = await fetch(`https://havendogs.onrender.com/auth/pets/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch animal details');
      }

      const data = await response.json();
      setAnimal(data);
      // Initialize editedAnimal with an empty object when animal data is fetched
      setEditedAnimal({});
    } catch (error) {
      console.error('Error fetching animal details:', error);
    }
  };

  useEffect(() => {
   
    fetchAnimalDetails();
  }, [id]);
  

  
  const handleEdit = () => {
    // Set editedAnimal to the current animal data when entering edit mode
    setEditedAnimal(animal);
    setEditMode(true);
  };
  
  const [imagesUpload, setImagesUpload] = useState([null, null, null]);
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);
  
  const handleDrop = (acceptedFiles, index) => {
    // Update state with the accepted file at the specified index
    const updatedImages = [...imagesUpload];
    updatedImages[index] = acceptedFiles[0];
    setImagesUpload(updatedImages);
  };

  const updatePicturePaths = async (petId, imageUrl, index) => {
    try {
      const response = await fetch(`https://havendogs.onrender.com/auth/pets/updatePicturePaths/${petId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imageUrl, index })
      });
  
      if (!response.ok) {
        throw new Error('Failed to update picture path');
      }
       fetchAnimalDetails();

      return true;
    } catch (error) {
      console.error('Error updating picture path:', error);
      return false;
    }
  };
  

  const handleUpload = async (index) => {
    const image = imagesUpload[index];
    if (!image) return;
  
    const imageRef = ref(storage, `userImages/${image.name + v4()}`);
    try {
      await uploadBytes(imageRef, image);
      const downloadURL = await getDownloadURL(imageRef);
      
      // Update the state with the uploaded image URL at the specified index
      setUploadedImageURLs(prevUrls => {
        const updatedUrls = [...prevUrls];
        updatedUrls[index] = downloadURL;
        return updatedUrls;
      });
  
      // Update picture paths for the professional after successful upload
      updatePicturePathsForAnimal(id, downloadURL, index);
      fetchAnimalDetails();
      console.log(animal);

    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  // Call this function after successful image uploads to update the picture paths
const updatePicturePathsForAnimal = async (petId, imageUrl, index) => {
  try {
    const success = await updatePicturePaths(petId, imageUrl, index);
    if (success) {
      console.log(`Picture path ${index + 1} updated successfully`);
      // Refresh the professional data after successful update
    } else {
      console.log('Failed to update picture path');
    }
  } catch (error) {
    console.error('Error updating picture path:', error);
  }
};

  // First dropzone
  const { getRootProps: getRootProps1, getInputProps: getInputProps1, isDragActive: isDragActive1 } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 0),
  });

  // Second dropzone
  const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 1),
  });

  // Third dropzone
  const { getRootProps: getRootProps3, getInputProps: getInputProps3, isDragActive: isDragActive3 } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 2),
  });

  const handleSave = async () => {
    try {
      const response = await fetch(`https://havendogs.onrender.com/auth/animals/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(editedAnimal),
      });

      if (!response.ok) {
        throw new Error('Failed to update animal details');
      }

      // Update the animal state with edited data
      setAnimal(editedAnimal);
      setEditMode(false);
    } catch (error) {
      console.error('Error updating animal details:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAnimal({ ...editedAnimal, [name]: value });
  };


  if (!animal) {
    return <div>Loading...</div>;
  }

  const animalTypes = ['Dog', 'Cat', 'Bird', 'Rabbit', 'Fish', 'Reptile', 'Other'];

  return (
    <div className="animal-details">
      <h2>Animal Details</h2>
      {editMode && (
  <div className="dropzone-container">
    {/* First dropzone */}
    <div {...getRootProps1()} className={`dropzone ${isDragActive1 ? 'active' : ''}`} style={getDropzoneStyle(isDragActive1)}>
      <input {...getInputProps1()} />
      {imagesUpload[0] ? (
        <p>Selected File: {imagesUpload[0].name}</p>
      ) : (
        <p>{isDragActive1 ? 'Drop the image file here...' : 'Drag \'n\' drop an image file here, or click to select one'}</p>
      )}
    </div>
    <button onClick={() => handleUpload(0)} className="button">Upload Image 1</button>

    {/* Second dropzone */}
    <div {...getRootProps2()} className={`dropzone ${isDragActive2 ? 'active' : ''}`} style={getDropzoneStyle(isDragActive2)}>
      <input {...getInputProps2()} />
      {imagesUpload[1] ? (
        <p>Selected File: {imagesUpload[1].name}</p>
      ) : (
        <p>{isDragActive2 ? 'Drop the image file here...' : 'Drag \'n\' drop an image file here, or click to select one'}</p>
      )}
    </div>
    <button onClick={() => handleUpload(1)} className="button">Upload Image 2</button>

    {/* Third dropzone */}
    <div {...getRootProps3()} className={`dropzone ${isDragActive3 ? 'active' : ''}`} style={getDropzoneStyle(isDragActive3)}>
      <input {...getInputProps3()} />
      {imagesUpload[2] ? (
        <p>Selected File: {imagesUpload[2].name}</p>
      ) : (
        <p>{isDragActive3 ? 'Drop the image file here...' : 'Drag \'n\' drop an image file here, or click to select one'}</p>
      )}
    </div>
    <button onClick={() => handleUpload(2)} className="button">Upload Image 3</button>
  </div>
)}


      <ul className="grid-container">
  <li><img src={animal.picturePath} alt="Professional 1" /></li>
  <li><img src={animal.picturePath1} alt="Professional 2" /></li>
  <li><img src={animal.picturePath2} alt="Professional 3" /></li>
</ul>

      {animal && (
        <>
          {/* <p>Animal ID: {animal._id}</p> */}
          <div className="form-field-container">
  <TextField
    name="name"
    label="Name"
    variant="outlined"
    fullWidth
    value={editMode ? editedAnimal.name : animal.name}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

<div className="form-field-container">
  <Select
    name="animalType"
    label="Type"
    variant="outlined"
    fullWidth
    value={editMode ? editedAnimal.animalType : animal.animalType}
    onChange={handleInputChange}
    disabled={!editMode}
  >
    {animalTypes.map((type) => (
      <MenuItem key={type} value={type}>{type}</MenuItem>
    ))}
  </Select>
</div>

          <div className="form-field-container">
  <TextField
    name="breed"
    label="Breed"
    variant="outlined"
    fullWidth
    value={editMode ? editedAnimal.breed : animal.breed}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

<div className="form-field-container">
  <TextField
    name="age"
    label="Age"
    variant="outlined"
    fullWidth
    type="number"
    value={editMode ? editedAnimal.age : animal.age}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

<div className="form-field-container">
  <TextField
    name="description"
    label="Description"
    variant="outlined"
    fullWidth
    multiline
    rows={4}
    value={editMode ? editedAnimal.description : animal.description}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

<div className="form-field-container">
  <TextField
    name="medicalRequirements"
    label="Medical Requirements"
    variant="outlined"
    fullWidth
    multiline
    rows={4}
    value={editMode ? editedAnimal.medicalRequirements : animal.medicalRequirements}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

<div className="form-field-container">
  <TextField
    name="foodRequirements"
    label="Food Requirements"
    variant="outlined"
    fullWidth
    multiline
    rows={4}
    value={editMode ? editedAnimal.foodRequirements : animal.foodRequirements}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

<div className="form-field-container">
  <TextField
    name="supplies"
    label="Supplies"
    variant="outlined"
    fullWidth
    multiline
    rows={4}
    value={editMode ? editedAnimal.supplies : animal.supplies}
    onChange={handleInputChange}
    disabled={!editMode}
  />
</div>

          {editMode ? (
            <Button variant="contained" onClick={handleSave}>Save</Button>
          ) : (
            <Button variant="contained" onClick={handleEdit}>Edit</Button>
          )}
        </>
      )}
    </div>
  
  );
};

export default AnimalDetails;

const getDropzoneStyle = (isDragActive) => ({
  border: `2px dashed ${isDragActive ? 'green' : '#cccccc'}`,
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
});
