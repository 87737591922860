import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Article.css';

function Article3() {

  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const winTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;
      const scrolled = (winTop / (docHeight - winHeight)) * 100;
      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">Why You Should Adopt, Not Shop: The Benefits of Pet Adoption</span>
        </div>
        <div className="scroll-line" style={{ width: `${scrollPercentage}%` }}></div>

        {/* Background Image */}
        <picture className="cs-background">
          {/* Mobile Image */}
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          {/* Tablet and above Image */}
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
      <section id="content-page-852">
  <div className="cs-container">
    <div className="cs-content">
      <h1 className="cs-title">
        Why You Should Adopt, Not Shop: The Benefits of Pet Adoption
      </h1>
      <h2>The Importance of Pet Adoption</h2>
      <h3>Saving Lives and Reducing Overpopulation</h3>
      <h4>Making a Positive Impact</h4>
      <p>
        Adopting a pet from a shelter or rescue organization is one of the most compassionate and responsible decisions a potential pet owner can make. By choosing to adopt, you are not only giving a loving home to an animal in need but also contributing to the solution of pet overpopulation and reducing the demand for unethical breeding practices.
      </p>
      <p>
        Every year, millions of pets end up in shelters due to various reasons such as abandonment, stray situations, or being surrendered by their owners. By adopting, you directly help decrease the number of animals in shelters and give them a second chance at a happy life. This act of kindness can save lives and provide animals with the love and care they deserve.
      </p>
      <h3>Key Benefits of Pet Adoption</h3>
      <ol>
        <li>Saving Lives: Providing a home to an animal that might otherwise be euthanized due to lack of space in shelters.</li>
        <li>Fighting Overpopulation: Reducing the number of animals that end up in shelters, helping to control the pet population.</li>
        <li>Supporting Ethical Practices: Discouraging the demand for pets from breeders that may operate under unethical conditions.</li>
      </ol>
      <h4>Advantages for Pet Owners</h4>
      <ul>
        <li>Cost-Effective: Adoption fees are generally lower than buying from a breeder, and many shelters provide initial veterinary care and vaccinations.</li>
        <li>Pre-Screened Pets: Animals in shelters are often evaluated for behavior and health, helping you find a pet that fits your lifestyle.</li>
        <li>Personal Satisfaction: Knowing that you have made a positive impact on an animal’s life and the broader community.</li>
      </ul>
    </div>
   
  </div>
</section>

    </div>
  );
}

export default Article3;
