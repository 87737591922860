import React from 'react';
import './Spay.css'

const SpayNeuterPrograms = () => {
    return (
        <div className="spay-neuter-programs">
      <div className="banner-712">
        <div className="cs-container" style={{textAlign:'center'}}>
          <span className="cs-int-title" style={{textAlign:'center'}}>Spay & Neuter Programs</span>
        </div>
        <picture className="cs-background">
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
            
            <section className="introduction">
                <h2>Introduction</h2>
                <p>
                    At Haven Pet Home and Animal Care Foundation, we are dedicated to improving the lives of animals 
                    through our Spay & Neuter Programs. These programs are crucial in controlling the animal population 
                    and ensuring that fewer animals end up abandoned or in shelters.
                </p>
            </section>
            
            <section className="importance">
                <h2>Why Spay & Neuter?</h2>
                <p>
                    Spaying and neutering not only help control the animal population and mortality but also provide numerous health 
                    benefits to the animals. It can prevent various health issues, reduce unwanted behaviors, and 
                    contribute to longer, healthier lives for pets.
                </p>
            </section>
            
            <section className="benefits">
                <h2>Benefits of Our Program</h2>
                <ul>
                    <li>Reduces the number of homeless pets</li>
                    <li>Prevents certain types of cancers and infections</li>
                    <li>Decreases aggressive behavior and roaming</li>
                    <li>Improves overall animal health and longevity</li>
                </ul>
            </section>
            
            <section className="how-it-works">
                <h2>How Our Program Works</h2>
                <p>
                    Our Spay & Neuter Programs are accessible to all animal owners in the community. We offer affordable 
                    services and collaborate with local veterinary clinics to ensure high-quality care for the animals. 
                    Here’s how you can participate:
                </p>
                <ol>
                    <li>Contact us to schedule an appointment.</li>
                    <li>Bring your animal to our clinic or a participating veterinary office.</li>
                    <li>Our team will perform the spay or neuter surgery with the utmost care and professionalism.</li>
                    <li>Post-surgery, we provide detailed aftercare instructions to ensure a smooth recovery for your animal.</li>
                </ol>
            </section>
            
            <section className="success-stories">
                <h2>Success Stories</h2>
                <p>
                    Over the years, our Spay & Neuter Programs have made a significant impact in the community. 
                    Here are some of the heartwarming stories of animals whose lives have been transformed by our efforts:
                </p>
                <ul>
                    <li><strong>Buddy:</strong> A stray dog who, after being neutered, found a loving home and became a therapy dog.</li>
                    <li><strong>Whiskers:</strong> A feral cat who was spayed and is now living a healthy life as a barn cat on a local farm.</li>
                </ul>
            </section>
            
            <section className="get-involved">
                <h2>How You Can Get Involved</h2>
                <p>
                    You can support our Spay & Neuter Programs by:
                </p>
                <ul>
                    <li>Donating to help fund surgeries for pets in need</li>
                    <li>Volunteering your time to assist with our community outreach efforts</li>
                    <li>Spreading the word about the importance of spaying and neutering pets</li>
                </ul>
                <p>
                    Together, we can make a difference in the lives of countless animals.
                </p>
            </section>
            
            <footer className="footer">
                <p>
                    Haven Pet Home and Animal Care Foundation is committed to creating a better world for animals 
                    through our Spay & Neuter Programs. Join us in our mission to rescue, rehabilitate, and find new 
                    homes for animals in need.
                </p>
                <p>Contact us at: <a href="mailto:info@havendogs.org">info@havendogs.org</a></p>
            </footer>
        </div>
    );
};

export default SpayNeuterPrograms;
