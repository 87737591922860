import React from 'react';
import './VaccinationProgram.css'

const VaccinationProgram = () => {
    return (
        <div className="vaccination-program">
            <div className="banner-712">
                <div className="cs-container" style={{ textAlign: 'center' }}>
                    <span className="cs-int-title" style={{ textAlign: 'center' }}>Vaccination Program</span>
                </div>
                <picture className="cs-background">
                    <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
                </picture>
            </div>

            <section className="introduction">
                <h2>Introduction</h2>
                <p>
                    At Haven Pet Home and Animal Care Foundation, our Vaccination Program is dedicated to ensuring the health and well-being of animals in our community. 
                    Vaccinations are a crucial part of preventive healthcare, protecting pets from various diseases and contributing to a healthier animal population.
                </p>
            </section>
            
            <section className="importance">
                <h2>Why Vaccination Program?</h2>
                <p>
                    Vaccinations play a vital role in preventing infectious diseases that can be life-threatening for pets. 
                    Our Vaccination Program aims to provide access to essential vaccines, promoting the health and longevity of pets and reducing the spread of diseases.
                </p>
            </section>
            
            <section className="benefits">
                <h2>Benefits of Our Program</h2>
                <ul>
                    <li>Prevents the spread of contagious diseases among pets</li>
                    <li>Ensures healthier and longer lives for vaccinated animals</li>
                    <li>Reduces the risk of disease outbreaks in the community</li>
                    <li>Provides affordable vaccination services to pet owners</li>
                    <li>Educates the community about the importance of vaccinations</li>
                </ul>
            </section>
            
            <section className="how-it-works">
                <h2>How Our Program Works</h2>
                <p>
                    Our Vaccination Program is designed to be accessible and efficient, ensuring that as many pets as possible receive the necessary vaccines. Here’s how our program works:
                </p>
                <ol>
                    <li>Outreach: We conduct community outreach to inform pet owners about upcoming vaccination clinics and the importance of vaccines.</li>
                    <li>Clinics: We organize regular vaccination clinics at various locations, making it convenient for pet owners to bring their pets.</li>
                    <li>Vaccination: Experienced veterinarians and technicians administer the vaccines, ensuring each pet receives the appropriate care.</li>
                    <li>Follow-up: We provide pet owners with vaccination records and information on when to schedule follow-up vaccinations.</li>
                </ol>
            </section>
            
            <section className="success-stories">
                <h2>Success Stories</h2>
                <p>
                    Our Vaccination Program has helped many pets lead healthier lives. Here are some of our success stories:
                </p>
                <ul>
                    <li><strong>Luna:</strong> A puppy who received her vaccinations and avoided a serious outbreak of parvovirus in her area.</li>
                    <li><strong>Shadow:</strong> A cat who was protected from feline leukemia virus (FeLV) thanks to our vaccination efforts.</li>
                </ul>
            </section>
            
            <section className="get-involved">
                <h2>How You Can Get Involved</h2>
                <p>
                    You can support our Vaccination Program by:
                </p>
                <ul>
                    <li>Donating to help cover the costs of vaccines and clinic operations</li>
                    <li>Volunteering your time to assist with community outreach and clinic setups</li>
                    <li>Spreading awareness about the importance of pet vaccinations</li>
                    <li>Bringing your pets to our vaccination clinics and encouraging others to do the same</li>
                </ul>
                <p>
                    Together, we can ensure that more pets receive the essential vaccinations they need to lead healthy and happy lives.
                </p>
            </section>
            
            <footer className="footer">
                <p>
                    Haven Pet Home and Animal Care Foundation is committed to promoting the health and well-being of pets through our Vaccination Program. 
                    Join us in our mission to rescue, rehabilitate, and find new homes for animals in need.
                </p>
                <p>Contact us at: <a href="mailto:info@havendogs.org">info@havendogs.org</a></p>
            </footer>
        </div>
    );
};

export default VaccinationProgram;
