import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EmailForm from './EmailForm';
import { useDropzone } from 'react-dropzone';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import { storage } from '../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import './ProfessionalDetails.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { message } from 'antd';
import { Skeleton } from '@mui/material';



const ProfessionalDetails = () => {
  const [professional, setProfessional] = useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user._id);
  const [newTicket, setNewTicket] = useState({ type: '', user: user, animals: [], status: 'open', tags: [], professional:professional });
  const [userAnimals, setUserAnimals] = useState([]);
  const [ticketStatus, setTicketStatus] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [showForm, setShowForm] = useState(false);

  const handleClick = async () => {
    await handleDelete();
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTicket({ ...newTicket, [name]: value });
  };

  const handleTagChange = (tag) => {
    const index = newTicket.tags.indexOf(tag);
    if (index === -1) {
      setNewTicket({ ...newTicket, tags: [...newTicket.tags, tag] });
    } else {
      setNewTicket({ ...newTicket, tags: newTicket.tags.filter((t) => t !== tag) });
    }
  };

  const handleAnimalChange = (animal) => {
    const index = newTicket.animals.findIndex((a) => a.name === animal.name);
    if (index === -1) {
      setNewTicket({ ...newTicket, animals: [...newTicket.animals, animal] });
    } else {
      const updatedAnimals = [...newTicket.animals];
      updatedAnimals.splice(index, 1);
      setNewTicket({ ...newTicket, animals: updatedAnimals });
    }
  };

  const createTicket = async (ticket) => {
    const hide = message.loading('Creating ticket...', 0);  // Show a loading message with no automatic timeout
    try {
      const response = await fetch('https://havendogs.onrender.com/tickets/createPost', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ticket)
      });
      hide();  // Dismiss the loading message
      if (!response.ok) {
        throw new Error('Failed to request service, Try again');
      }
      const responseData = await response.json();
      message.success('Service was requested successfully'); // Show success message
      return responseData; // Return response data
    } catch (error) {
      hide();  // Ensure loading message is hidden on error
      console.error('Error creating ticket:', error);
      message.error('Error creating ticket: ' + error.message); // Show error message
      throw error; // Re-throw error to handle it outside
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createTicket(newTicket);
      await sendNotification();
      await checkTicketStatus(); // Call checkTicketStatus after creating the ticket
    } catch (error) {
      setNewTicket({ type: '', user: user, animals: [], status: 'open', tags: [], professional:professional });
      console.error('Failed to create ticket:', error);
      // Handle error if needed
    }
  };
  
  const [subject, setSubject] = useState('New Request');
  const [text, setText] = useState('Check out this new Request.');

  const sendNotification = async (e) => {

    try {
      const response = await fetch('https://havendogs.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subject: subject,
          text: text
        })
      });

      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Error sending email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    const fetchAnimals = async () => {
      try {
        const response = await fetch(`https://havendogs.onrender.com/auth/animals/${user._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch animals');
        }

        const data = await response.json();
        setUserAnimals(data);
      } catch (error) {
        console.error('Error fetching animals:', error);
      }
    };

    fetchAnimals();
  }, [user._id]);
  const fetchProfessionalDetails = async () => {
    try {
      // Display loading message if needed
      // ...

      // Make an API request to fetch details of the professional based on the 'id'
      const response = await fetch(`https://havendogs.onrender.com/auth/getProfessionalById/${id}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setProfessional(data);

      // Display success message if needed
      // ...
    } catch (error) {
      // Display error message if needed
      // ...
      console.error('Error fetching professional details:', error);
    }
  };

  const handleDelete = async () => {
    const hide = message.loading('Deleting ticket...', 0);  // Start a loading message with no automatic timeout
    try {
      const response = await fetch(`https://havendogs.onrender.com/tickets/tickets/${ticketId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      hide();  // Dismiss the loading message
      if (response.ok) {
        await checkTicketStatus();
        console.log('Ticket deleted successfully');
        message.success('Ticket deleted successfully');  // Show a success message
        // Optionally, you can perform additional actions after successful deletion
      } else {
        throw new Error('Failed to delete ticket');
      }
    } catch (error) {
      hide();  // Make sure to hide the loading message if an error occurs
      console.error('Error canceling service:', error);
      message.error('Error canceling service: ' + error.message);  // Show an error message
    }
  };
  


  const checkTicketStatus = async () => {
    try {
      // Check if there is a ticket associated with the user and professional IDs
      const response = await fetch(`https://havendogs.onrender.com/tickets/get-status/${userId}/${id}`);
      if (response.ok) {
        const { ticket } = await response.json(); // Extract the ticket object from the response
        if (ticket) {
          setTicketId(ticket._id)
          // If ticket found, set the ticket status
          setTicketStatus(ticket.status);
        } else {
          // If ticket not found, set status as 'Not found'
          setTicketStatus('Not found');
        }
      } else {
        throw new Error('Failed to check ticket status');
      }
    } catch (error) {
      console.error('Error checking ticket status:', error);
    }
  };
  
  useEffect(() => {
    checkTicketStatus();

    fetchProfessionalDetails();
  }, [user._id, id]); // Re-run the effect when 'id' changes

    // Function to represent ratings as stars
    const renderStars = (numStars) => {
      const stars = [];
      for (let i = 0; i < numStars; i++) {
        stars.push(<span key={i}>⭐</span>);
      }
      return stars;
    };

    useEffect(() => {
      // Update newTicket state after professional has been fetched
      setNewTicket({ ...newTicket, professional: professional, user: user });
    }, [professional, user]);


    const [imagesUpload, setImagesUpload] = useState([null, null, null]);
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);

  const updatePicturePaths = async (professionalId, imageUrl, index) => {
    try {
      const response = await fetch(`https://havendogs.onrender.com/auth/professionals/updatePicturePaths/${professionalId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imageUrl, index })
      });
  
      if (!response.ok) {
        throw new Error('Failed to update picture path');
      }
  
      return true;
    } catch (error) {
      console.error('Error updating picture path:', error);
      return false;
    }
  };
  

    const handleDrop = (acceptedFiles, index) => {
      // Update state with the accepted file at the specified index
      const updatedImages = [...imagesUpload];
      updatedImages[index] = acceptedFiles[0];
      setImagesUpload(updatedImages);
    };

    const handleUpload = async (index) => {
      const image = imagesUpload[index];
      if (!image) return;
    
      const imageRef = ref(storage, `professionalImages/${image.name + v4()}`);
      try {
        await uploadBytes(imageRef, image);
        const downloadURL = await getDownloadURL(imageRef);
        
        // Update the state with the uploaded image URL at the specified index
        setUploadedImageURLs(prevUrls => {
          const updatedUrls = [...prevUrls];
          updatedUrls[index] = downloadURL;
          return updatedUrls;
        });
    
        // Update picture paths for the professional after successful upload
        updatePicturePathsForProfessional(id, downloadURL, index);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };

// Call this function after successful image uploads to update the picture paths
const updatePicturePathsForProfessional = async (professionalId, imageUrl, index) => {
  try {
    const success = await updatePicturePaths(professionalId, imageUrl, index);
    if (success) {
      console.log(`Picture path ${index + 1} updated successfully`);
      // Refresh the professional data after successful update
      fetchProfessionalDetails();
    } else {
      console.log('Failed to update picture path');
    }
  } catch (error) {
    console.error('Error updating picture path:', error);
  }
};

    

 // First dropzone
 const { getRootProps: getRootProps1, getInputProps: getInputProps1, isDragActive: isDragActive1 } = useDropzone({
  accept: 'image/*',
  onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 0),
});

// Second dropzone
const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({
  accept: 'image/*',
  onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 1),
});

// Third dropzone
const { getRootProps: getRootProps3, getInputProps: getInputProps3, isDragActive: isDragActive3 } = useDropzone({
  accept: 'image/*',
  onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 2),
});
    
if (Object.keys(professional).length === 0) {
  return <div className="professional-profile skeleton">
  <Skeleton variant="text" width="60%" height={60} style={{ marginBottom: 20 }} /> {/* Name */}
  <Skeleton variant="rectangular" width="100%" height={300} style={{ marginBottom: 20 }} /> {/* Profile image */}
  <Skeleton variant="text" width="40%" height={30} style={{ marginBottom: 10 }} /> {/* Profession */}
  <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 20 }} /> {/* About me */}
  <Skeleton variant="rectangular" width="100%" height={200} /> {/* Service form placeholder */}
</div>;
}

return (
  <div className="professional-profile">
    <h1 className="name">{`${professional.firstName} ${professional.lastName}`}</h1>
    <div className="profile-images-container">
      <div className="profile-image">
        <img src={professional.picturePath1} alt="Profile" />
      </div>
      {/* <div className="profile-image">
        <img src={professional.picturePath2} alt="Profile" />
      </div>
      <div className="profile-image">
        <img src={professional.picturePath3} alt="Profile" />
      </div> */}
    </div>
  
    {user.admin && (
      <>
        <div className="dropzone-container">
          <div {...getRootProps1({ className: 'dropzone' })}>
            <input {...getInputProps1()} />
            {imagesUpload[0] ? (
              <p>Selected File: {imagesUpload[0].name}</p>
            ) : (
              <p>
                {isDragActive1
                  ? 'Drop the image file here...'
                  : 'Drag \'n\' drop an image file here, or click to select one'}
              </p>
            )}
          </div>
          <button className="upload-button" onClick={() => handleUpload(0)}>Upload Image 1</button>
        </div>
  
        <div className="dropzone-container">
          <div {...getRootProps2({ className: 'dropzone' })}>
            <input {...getInputProps2()} />
            {imagesUpload[1] ? (
              <p>Selected File: {imagesUpload[1].name}</p>
            ) : (
              <p>
                {isDragActive2
                  ? 'Drop the image file here...'
                  : 'Drag \'n\' drop an image file here, or click to select one'}
              </p>
            )}
          </div>
          <button className="upload-button" onClick={() => handleUpload(1)}>Upload Image 2</button>
        </div>
  
        <div className="dropzone-container">
          <div {...getRootProps3({ className: 'dropzone' })}>
            <input {...getInputProps3()} />
            {imagesUpload[2] ? (
              <p>Selected File: {imagesUpload[2].name}</p>
            ) : (
              <p>
                {isDragActive3
                  ? 'Drop the image file here...'
                  : 'Drag \'n\' drop an image file here, or click to select one'}
              </p>
            )}
          </div>
          <button className="upload-button" onClick={() => handleUpload(2)}>Upload Image 3</button>
        </div>
      </>
    )}
  <div className="profession-service-container">
  <p className="profession">Profession: {professional.profession}</p>
  <Button onClick={toggleFormVisibility} variant="contained" className="service-button">
    {showForm ? 'Hide' : 'Get Service'}
  </Button>
</div>
    {ticketStatus !== 'closed' && ticketStatus !== 'Not found' ? (
  <Card variant="outlined" className="service-card">
    <CardContent>
      <div>
        <p>Ticket status: {ticketStatus}</p>
        <p>We will reach out to you within 24 hours.</p>
        <button onClick={handleClick} className="delete-ticket-button">Delete Ticket</button>
      </div>
    </CardContent>
  </Card>
) : (
  <>
    {showForm && (
      <form onSubmit={handleSubmit} className="service-form">
        {professional.profession !== 'adopt me' ? (
          <>
            <div>
              <label>Tick The Service You want Provided</label>
              {professional.tags
                .filter(tag => tag !== 'cats' && tag !== 'dogs')
                .map(tag => (
                  <FormControlLabel
                    key={tag}
                    control={
                      <Checkbox
                        checked={newTicket.tags && newTicket.tags.includes(tag)}
                        onChange={() => handleTagChange(tag)}
                      />
                    }
                    label={`Pet ${tag}`}
                  />
                ))}
            </div>
            <Divider />
            <div>
              <label>Your Pets:</label>
              {userAnimals.length > 0 ? (
                userAnimals.map((animal) => (
                  <FormControlLabel
                    key={animal.name}
                    control={
                      <Checkbox
                        checked={newTicket.animals.some((a) => a.name === animal.name)}
                        onChange={() => handleAnimalChange(animal)}
                      />
                    }
                    label={animal.name}
                  />
                ))
              ) : (
                <>
                  <p>You have not added any pets yet.</p>
                  <Link to="/add-pet" className="add-pet-link">Add a pet</Link>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <label>Tick The Service You want Provided</label>
              {professional.tags
                .filter(tag => tag !== 'cats' && tag !== 'dogs')
                .map(tag => (
                  <FormControlLabel
                    key={tag}
                    control={
                      <Checkbox
                        checked={newTicket.tags && newTicket.tags.includes(tag)}
                        onChange={() => handleTagChange(tag)}
                      />
                    }
                    label="Adopt this pet for free"
                  />
                ))}
            </div>
            <Divider />
            <div>
              <p>Your home will be inspected to ensure a safe environment</p>
            </div>
          </>
        )}
        <Divider />
        <div>
          <TextField
            label="Any Detail We Should Know?"
            type="text"
            name="type"
            value={newTicket.type}
            onChange={handleInputChange}
            required
            className="service-details-input"
          />
        </div>
        <div className="button-container">
          <Button type="submit" variant="contained" className="service-submit-button">
            Request Service
          </Button>
        </div>  
      </form>
    )}
  </>
)}
    <h2 className="details-heading">About Me</h2>
{professional.profession === 'adopt me' && (
  <>
    <p>Age: {professional.age}</p>
    <p>Breed: {professional.breed}</p>
  </>
)}
<pre className="details-content">{professional.details}</pre>

    <div className="professional-details-container">
    </div>
  </div>
);
};

export default ProfessionalDetails;

const getDropzoneStyle = (isDragActive) => ({
  border: `2px dashed ${isDragActive ? 'green' : '#cccccc'}`,
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
});
