import React from 'react';
import './AboutPage.css'

function ServicesPage() {
  return (
    <div>
      <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">Services</span>
        </div>
        {/* Background Image */}
        <picture className="cs-background">
          {/* Mobile Image */}
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          {/* Tablet and above Image */}
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
      <section id="content-page-852">
        <div className="cs-container">
          <div className="cs-content">
            <h1 className="cs-title">Our Services</h1>
            <p>At Haven Pet Home and Animal Care Foundation, we offer a wide range of services to support the well-being of animals in our community.</p>
            <h3>Rescue and Rehabilitation</h3>
            <p>Our primary focus is on rescuing abandoned, abused, or neglected animals and providing them with the necessary medical care and rehabilitation. We work closely with local animal control agencies and shelters to identify animals in need and offer them a safe haven.</p>
            <h3>Adoption Services</h3>
            <p>Once our rescued animals have recovered and are ready for adoption, we carefully match them with loving forever homes. Our adoption process involves thorough screenings and counseling to ensure a successful match between the animal and the prospective family.</p>
            <h3>Spay and Neuter Programs</h3>
            <p>We understand the importance of controlling animal overpopulation and reducing the number of animal mortality in our community. Our spay and neuter programs aim to provide affordable and accessible options for animal owners to have their animals neutered.</p>
            <h3>Vaccination and Preventive Care</h3>
<p>Regular vaccinations and preventive care are essential for maintaining the health and well-being of pets. We offer low-cost vaccination clinics and provide information on preventive care measures to promote responsible animal ownership.</p>
<h3>Foster Care Program</h3>
<p>Our foster care program plays a crucial role in our efforts. Dedicated foster families provide temporary homes for animals in need, allowing them to recover and adjust in a loving environment before they are ready for adoption.</p>
<h3>Community Outreach and Education</h3>
<p>We believe in the power of education to create lasting change. Our community outreach programs aim to raise awareness about animal welfare issues, promote responsible animal ownership, and encourage compassion toward all living beings.</p>
<h3>Volunteer Opportunities</h3>
<p>We welcome passionate individuals who share our love for animals to join our volunteer team. Volunteers play a vital role in supporting our efforts, from assisting with animal care and socialization to participating in fundraising events and community outreach initiatives.</p>
<p>At Haven Pet Home and Animal Care Foundation, we are committed to making a positive impact on the lives of animals in need. Through our comprehensive services and the dedication of our team and volunteers, we strive to create a better world for our furry companions.</p>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ServicesPage;