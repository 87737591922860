import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import './HorseRescue.css'
const HorseRescueArticle = () => {
  return (
    <article className="rescue-article">
      <header className="article-header">
        <h1 className="article-title">Welcome Rafah to Haven Dogs: Top Tips for New Horse Owners</h1>
        <div className="author-info">
          <img 
            src="/svg/logo.svg" 
            alt="Author" 
            className="author-image"
          />
          <div>
            <p className="author-name">Haven Dogs Team</p>
            <p className="author-role">Equine Care Specialists | July 23, 2024</p>
          </div>
        </div>
        <img 
          src="/images/rafa-horse.jpg" 
          alt="Rafah, a beautiful 2-year-old male horse" 
          className="header-image"
        />
      </header>

      <section className="article-section">
        <h2 className="section-title">Introducing Rafah: Our Newest Family Member</h2>
        <p>
          We are thrilled to announce a new addition to the Haven Dogs family – Rafah, a beautiful 2-year-old male horse from Gombe State, Nigeria. With his striking features and gentle temperament, Rafah has already captured our hearts. His arrival not only brings joy to our sanctuary but also presents an opportunity to share valuable insights with his new family and other aspiring horse owners.
        </p>
        <p>
          Rafah's dad recently reached out to us for tips on how to take care of him, and we are excited to share our best advice for new horse owners. Whether you're welcoming your first horse or looking to improve your equine care routine, these tips will help ensure your horse's health, happiness, and well-being.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">1. Creating a Comfortable Home</h2>
        <p>
          One of the first steps in welcoming Rafah, or any horse, is to ensure they have a comfortable living space. Horses need a shelter that protects them from harsh weather conditions while allowing for proper ventilation. For Rafah, we've prepared a spacious stable with:
        </p>
        <ul>
          <li>Clean, dry bedding made of straw or wood shavings</li>
          <li>Good ventilation to prevent respiratory issues</li>
          <li>Safe, sturdy walls and doors to prevent injury</li>
          <li>Access to a secure outdoor area for exercise and grazing</li>
        </ul>
        <p>
          Remember, a happy horse is one that feels safe and comfortable in its environment. Take time to horse-proof the area, removing any potential hazards or toxic plants.
        </p>
        <img 
          src="/images/rafa-horse-2.jpg" 
          alt="Rafah's comfortable new stable" 
          className="section-image"
        />
      </section>

      <section className="article-section">
        <h2 className="section-title">2. Nutrition: Fueling Rafah's Growth and Health</h2>
        <p>
          At two years old, Rafah is still growing and requires a carefully balanced diet. Here are our top nutrition tips:
        </p>
        <ul>
          <li>Provide high-quality forage (hay or grass) as the foundation of the diet</li>
          <li>Offer clean, fresh water at all times</li>
          <li>Supplement with a balanced grain mix suitable for young horses</li>
          <li>Introduce any dietary changes gradually to avoid digestive upset</li>
          <li>Consider adding a vitamin and mineral supplement, especially if local forage is deficient</li>
        </ul>
        <p>
          We've worked with a equine nutritionist to develop a diet plan specifically for Rafah, taking into account his age, weight, and activity level. We recommend all new horse owners consult with a veterinarian or equine nutritionist to create a tailored feeding program.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">3. Exercise and Training: Nurturing Body and Mind</h2>
        <p>
          Regular exercise is crucial for Rafah's physical and mental well-being. As a young horse, he has plenty of energy to burn and a curious mind to stimulate. Here's how we're approaching Rafah's exercise and training:
        </p>
        <ul>
          <li>Daily turnout in a secure pasture for free movement and grazing</li>
          <li>Gradual introduction to basic groundwork and handling exercises</li>
          <li>Short, regular training sessions to build trust and teach basic commands</li>
          <li>Incorporation of varied activities to prevent boredom and encourage mental stimulation</li>
        </ul>
        <p>
          Remember, patience is key when working with young horses like Rafah. Consistent, gentle handling will help build a strong bond and set the foundation for more advanced training in the future.
        </p>
        <img 
          src="/api/placeholder/800/500" 
          alt="Rafah enjoying exercise in the pasture" 
          className="section-image"
        />
      </section>

      <section className="article-section">
        <h2 className="section-title">4. Health Care: Preventive Measures for a Happy Horse</h2>
        <p>
          Keeping Rafah healthy involves more than just responding to illness. A proactive approach to health care can prevent many common issues. Here's our health care plan for Rafah:
        </p>
        <ul>
          <li>Regular veterinary check-ups, at least twice a year</li>
          <li>Up-to-date vaccinations based on local disease risks</li>
          <li>Routine deworming as recommended by our veterinarian</li>
          <li>Daily health checks, including monitoring food and water intake</li>
          <li>Regular dental examinations and float as needed</li>
          <li>Hoof care with trimming or shoeing every 6-8 weeks</li>
        </ul>
        <p>
          We've established a relationship with a local equine veterinarian who will oversee Rafah's health care. We strongly advise all horse owners to do the same, ensuring you have professional support for both routine care and emergencies.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">5. Grooming: Bonding Time and Health Check</h2>
        <p>
          Regular grooming is not just about keeping Rafah looking his best – it's an excellent opportunity for bonding and health monitoring. Our grooming routine includes:
        </p>
        <ul>
          <li>Daily brushing to remove dirt and debris</li>
          <li>Hoof picking to prevent thrush and check for injuries</li>
          <li>Mane and tail detangling to prevent matting</li>
          <li>Bathing as needed, being careful not to strip natural oils</li>
          <li>Using grooming time to check for any cuts, swellings, or abnormalities</li>
        </ul>
        <p>
          Grooming sessions are a perfect time to strengthen your bond with your horse. For Rafah, these sessions help him become more comfortable with human touch and handling, which will be beneficial for his future training and care.
        </p>
      </section>

      <Alert severity="info" className="rescue-alert">
        <AlertTitle>A Note on Climate Considerations</AlertTitle>
        As Rafah comes from Gombe State, he's accustomed to a tropical savanna climate. New owners in different climate zones should be mindful of their horse's adjustment period and provide appropriate shelter and care to ensure comfort in their new environment.
      </Alert>

      <section className="article-section">
        <h2 className="section-title">Conclusion: A Journey of Love and Learning</h2>
        <p>
          Welcoming Rafah to Haven Dogs has been a joyous occasion, and we're excited to see him thrive in his new home. To Rafah's family and all new horse owners, remember that horse ownership is a journey of continuous learning and adaptation. Every horse is unique, with its own personality and needs. 
        </p>
        <p>
          By providing a loving home, proper nutrition, regular exercise, preventive health care, and plenty of attention, you're setting the foundation for a rewarding relationship with your equine companion. Don't hesitate to seek advice from professionals and experienced horse owners – the equestrian community is often very supportive and willing to share knowledge.
        </p>
        <p>
          We at Haven Dogs are always here to support you on your horse ownership journey. Here's to many happy years with your new four-legged family member!
        </p>
      </section>

      <footer className="article-footer">
        <p>
          For more information on horse care or to learn about our other rescue animals, visit us at Haven Dogs. Together, we can provide loving homes for all animals in need.
        </p>
      </footer>
    </article>
  );
};

export default HorseRescueArticle;