import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const BlackieRescueArticle = () => {
  return (
    <article className="rescue-article">
      <header className="article-header">
        <h1 className="article-title">Meet Blackie: A Tale of Rescue and Hope</h1>
        <div className="author-info">
          <img 
            src="\images\pinkfiligre.png" 
            alt="Rescue Team" 
            className="author-image"
          />
          <div>
            <p className="author-name">Rescue Team</p>
            <p className="author-role">Dog Rescue Coordinator | September 20, 2024</p>
          </div>
        </div>
        <img 
          src="/images/blackie2.jpg" 
          alt="Blackie the rescued dog" 
          className="header-image"
        />
      </header>

      <section className="article-section">
        <h2 className="section-title">Blackie's Journey</h2>
        <p>
          Blackie's story began in the bustling city of Lagos, where she was found seeking shelter in a carpenter's shop. Our team received a heartbreaking report: this young, indigenous breed puppy had been surviving on her own for days without proper food or care.
        </p>
        <p>
          Upon hearing about Blackie's plight, our rescue team sprang into action. We found a scared but gentle soul, her dark eyes reflecting the hardship she'd endured. Despite her ordeal, Blackie's spirit remained unbroken.
        </p>
        <p>
          She wagged her tail nervously as we approached, her timid nature overshadowed by a quiet resilience. Her ribs showed through her short, black coat, but a spark of hope still flickered in her eyes. We knew we had to act quickly, not just to save her life, but to offer her the love and care she'd missed out on.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">A New Chapter</h2>
        <p>
          Thanks to the dedication of our team and the support of our community, we were able to bring Blackie to safety. After a thorough health check and some much-needed TLC, we're thrilled to report that Blackie is now in good health and ready for her next adventure.
        </p>
        <p>
          At the shelter, Blackie began to thrive. She formed bonds with the other rescued dogs and quickly became a staff favorite with her affectionate nature. Her playfulness returned, and soon she was chasing toys, making friends, and learning to trust again.
        </p>
        <p>
          However, Blackie's journey isn't over yet. Her ultimate goal is to find a forever home where she can feel safe and loved. We believe there's a special person or family out there just waiting for her, and we won't stop until we find them.
        </p>
        <img 
  src="/images/blackie1.jpg"
  alt="Blackie is looking for a new home" 
          className="section-image"
        />
      </section>

      <Alert>
        <AlertTitle>Adoption Information</AlertTitle>
        <ul>
          <li><strong>Name:</strong> Blackie</li>
          <li><strong>Age:</strong> 6 months</li>
          <li><strong>Breed:</strong> Indigenous</li>
          <li><strong>Location:</strong> Lagos</li>
          <li><strong>Adoption Fee:</strong> Free to the right home</li>
        </ul>
      </Alert>

      <section className="article-section">
        <h2 className="section-title">How You Can Help</h2>
        <p>
          If you're interested in adopting Blackie or learning more about her, please contact us at Haven Dogs. Even if you can't adopt, you can still make a difference:
        </p>
        <ol>
          <li>Share Blackie's story on social media to help us find her forever home.</li>
          <li>Volunteer at our shelter – every extra hand helps us rescue and care for more animals.</li>
          <li>Donate to support our rescue efforts. Every contribution, no matter the size, goes directly toward food, medical care, and shelter for dogs like Blackie.</li>
        </ol>
        <p>
          In addition, consider fostering one of our dogs. Fostering offers them a temporary, loving home until a permanent family can be found. It's a rewarding experience that truly makes a difference in an animal's life.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">Why Blackie's Story Matters</h2>
        <p>
          Blackie's story is just one of many. Every day, dogs like her face uncertainty, hunger, and neglect. But with your help, we can give them a second chance. When you adopt or support a rescue, you're not just changing one life; you're impacting a whole network of lives.
        </p>
        <p>
          Blackie represents hope for so many animals who are still waiting for their moment to be seen, cared for, and loved. Your support ensures that we can continue our mission to rescue, rehabilitate, and rehome these deserving animals.
        </p>
      </section>

      <footer className="article-footer">
        <p>
          Remember, when you adopt, you're not just saving one life – you're making room for us to rescue another animal in need. Let's work together to give Blackie the happily ever after she deserves!
        </p>
        <p className="contact-info">
          For more information, reach out to us at <a href="mailto:info@havendogs.org">info@havendogs.org</a> or call us at 0810-969-0608. Together, we can make a difference.
        </p>
      </footer>
    </article>
  );
};

export default BlackieRescueArticle;
