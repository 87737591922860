import React from 'react';
import './AboutUsPage.css';
import './AboutPage.css';
import { Link } from 'react-router-dom';

function AboutUsPage() {
  return (
    <div>
      <div className="banner-712">
        <div className="cs-container" style={{textAlign:'center'}}>
          <span className="cs-int-title" style={{textAlign:'center'}}>About Us</span>
        </div>
        <picture className="cs-background">
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
      <section id="content-page-852">
        <div className="cs-container">
          <div className="cs-content">
          <h1 className="cs-title">Haven Pet Home and Animal Care Foundation</h1>

            <div className="content-with-images">
            
            <div className="text-content">
      <p>Our mission is to rescue, rehabilitate, and find new homes for animals in need.</p>
      <p>We are a compassionate and dedicated organization with a deep love for animals and a strong commitment to their well-being. Over the years, we have demonstrated our passion for animal welfare through various endeavors, including rescuing abandoned pets, fostering injured wildlife, and volunteering at local animal shelters.</p>
      <p>
      Our natural empathy and nurturing nature enable us to connect with animals on a profound level, understanding their needs and providing them with the care and compassion they deserve.
      </p>
      <h3>Our Initiatives</h3>
      <ul>
        <li>
          <strong>SPAY &amp; NEUTER PROGRAMS</strong>
          <p>Controlling pet population through accessible sterilization services. <Link to="/initiatives/spay-neuter-programs">Read More</Link>
</p>
        </li>
        <li>
          <strong>SENIOR PROGRAMS</strong>
          <p>Providing care and finding homes for older pets often overlooked in shelters. <Link to="/initiatives/senior-programs">Read More</Link></p>
          
        </li>
        <li>
          <strong>INDIGENOUS DOG PROGRAMS</strong>
          <p>Preserving and protecting native dog breeds and their habitats. <Link to="/initiatives/indigenous-dog-programs">Read More</Link>
</p>
        </li>
        <li>
          <strong>STREET DOG PROGRAM</strong>
          <p>Improving the lives of stray dogs through feeding, medical care, and adoption efforts. <Link to="/initiatives/street-dog-program">Read More</Link></p>
          
        </li>
        <li>
          <strong>VACCINATION PROGRAM</strong>
          <p>Preventing diseases in pets and strays through comprehensive vaccination drives. <Link to="/initiatives/vaccination-program">Read More</Link></p>
          
        </li>
      </ul>
    </div>
              <div className="image-container">
                <img src="https://images.unsplash.com/photo-1537151608828-ea2b11777ee8?q=80&w=1388&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image 1" />
                <img src="https://plus.unsplash.com/premium_photo-1667955010510-e8cfea4afdf4?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image 2" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="meet-us-1021">
      <h1 className='meet-our-team' style={{color:'#fff', textAlign:'center', }}>Meet The Team</h1>
      <ul class="underline">
      <li></li>
    </ul>
        <div className="about-us-container" style={{paddingLeft:'5%', paddingRight:'5%'}}>
          <div className="john-card">
            <img aria-hidden="true" loading="lazy" decoding="async" src="/images/fahidas passport.jpg" alt="Fahida" width="305" height="335" />
            <div className="john-info">
              <span className="john-name">Fahida Emetumah</span>
              <span className="john-job">Co-Founder</span>
            </div>
          </div>
          <div className="about-us-content">
            <h2 className="about-us-title">Meet Fahida, Co-Founder.</h2>
            <p className="about-us-text">
              Fahida a compassionate and dedicated individual with a deep love for animals and a strong commitment to their well-being. Over the years, She has demonstrated her passion for animal welfare through various endeavors, including rescuing abandoned pets, fostering injured wildlife, and volunteering at local animal shelters.
            </p>
            <p className="about-us-text">
              Her natural empathy and nurturing nature enable me to connect with animals on a profound level, understanding their needs and providing them with the care and compassion they deserve. She is driven by a desire to make a positive impact in the lives of animals, advocating for their rights and working tirelessly to create a better world for them. With a heart full of love and a determination to make a difference, she strives to be a voice for the voiceless and a champion for animal welfare in her community and beyond.
            </p>      
          </div>
        </div>
        <div className="about-us-container" style={{paddingLeft:'5%', paddingRight:'5%'}}>
          <div className="about-us-content">
            <h2 className="about-us-title">Tracy, Our Co-Founder.</h2>
            <p className="about-us-text">
              Tracy is a dedicated and passionate individual with a profound commitment to animal welfare. Throughout her career, she has consistently demonstrated her love for animals through various initiatives, including organizing community-wide pet adoption events, implementing educational programs on responsible pet ownership, and collaborating with local veterinarians to provide affordable care for pets in need.
            </p>
            <p className="about-us-text">
              her innovative approach to animal welfare and her ability to build strong partnerships within the community have been instrumental in expanding the reach and impact of our organization. John's vision and leadership continue to drive our mission forward, inspiring others to join in our efforts to create a world where every animal is treated with kindness and respect.
            </p>      
          </div>
          <div className="john-card">
            <picture className="john-picture">
              <source media="(max-width: 767px)" srcSet="/images/tracys profile.jpg" />
              <source media="(min-width: 768px)" srcSet="/images/tracys profile.jpg" />
              <img aria-hidden="true" loading="lazy" decoding="async" src="/images/tracys profile.jpg" alt="John" width="305" height="335" />
            </picture>
            <div className="john-info">
              <span className="john-name">Tracy Anele</span>
              <span className="john-job">Co-Founder</span>
            </div>
          </div>
        </div>
      </section>

      <section id="content-page-852">
        <div className="cs-container">
          <div className="cs-content">
            <h1 className="cs-title">Who We Are</h1>
            <p>Haven Pet Home and Animal Care Foundation is a non-profit organization dedicated to the rescue, rehabilitation, and adoption of animals in need. We are a team of passionate individuals who share a deep love for animals and a commitment to their well-being.</p>
            <p>Our organization was founded on the belief that every animal deserves a loving home and a second chance at life. We work tirelessly to provide medical care, shelter, and rehabilitation to abandoned, abused, or neglected animals, helping them heal both physically and emotionally.</p>
            <p>Our team consists of dedicated volunteers, veterinarians, and animal care professionals who work hand in hand to ensure the highest level of care and attention for every animal that comes through our doors. We are committed to finding forever homes for our rescued animals, carefully matching them with loving families who can provide them with the care and support they need.</p>
            <h3>Our Core Values</h3>
            <ul>
              <li><strong>Compassion.</strong> We treat every animal with kindness, empathy, and respect.</li>
              <li><strong>Dedication.</strong> We are committed to the well-being of animals and work tirelessly to improve their lives.</li>
              <li><strong>Education.</strong> We strive to educate the community about responsible pet ownership and the importance of animal welfare.</li>
              <li><strong>Collaboration.</strong> We work closely with other animal welfare organizations, veterinarians, and the community to achieve our goals.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUsPage;
