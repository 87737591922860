import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControl, Select, MenuItem, InputLabel
} from "@mui/material";

import { message } from "antd";
import { Formik, } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import { useDropzone } from 'react-dropzone';
import { storage } from '../../firebase';
import { v4 } from "uuid";
import { uploadBytes,ref, getDownloadURL  } from "firebase/storage";
import locations from "./locations";

const registerSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  email: yup
    .string()
    // .email("invalid email")
    .required("required"),
  password: yup.string().required("required"),
  address: yup.string().required("Street Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
});

const loginSchema = yup.object().shape({
  email: yup
    .string()
    // .email("invalid email")
    .required("required"),
  password: yup.string().required("required"),
});

const initialValuesRegister = {
  firstName: "",
  email: "",
  password: "",
  picture: "",
};

const initialValuesLogin = {
  email: "",
  password: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const [messageApi, contextHolder] = message.useMessage({
       style: {
      zIndex: 100000, // High z-index to ensure it's above other elements
    },
  });

 const register = async (values, onSubmitProps, imageDownloadURL) => {
  messageApi.loading({ content: "Creating A New Account... this might take a minute", key: "registerMessage", duration: 20000 });
   
  // Create a new FormData object
  const formData = new FormData();

  // Append form fields to FormData
  formData.append('firstName', values.firstName);
  formData.append('email', values.email);
  formData.append('password', values.password);
  formData.append('address', values.address);
  formData.append('phoneNumber', values.phoneNumber);

  // Append selected state and LGA to the FormData object
  formData.append('state', selectedState);
  formData.append('lga', selectedLGA);

  // Append the image download URL to the FormData object
  formData.append('picturePath', imageDownloadURL);

  // Send the form data to the backend
  const savedUserResponse = await fetch("https://havendogs.onrender.com/auth/register", {
    method: "POST",
    body: formData,
  });
  // Parse the response JSON
  const savedUser = await savedUserResponse.json();
  
  // Reset the form
  onSubmitProps.resetForm();

  // Handle success message and page navigation
  if (savedUser) {
    messageApi.success({ content: 'Registration successful! log in', key: 'registerMessage' });
    setTimeout(messageApi.destroy, 2000);
    setPageType("login");
  }
};
  const login = async (values, onSubmitProps) => {
    // Show loading message
    // Display loading message
    messageApi.loading({ content: "Logging in...", key: "LoginMessage", duration:0 });
    try {
      const loggedInResponse = await fetch("https://havendogs.onrender.com/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (loggedInResponse.ok) {
        const loggedIn = await loggedInResponse.json();
        console.log(loggedIn);
        onSubmitProps.resetForm();
        if (loggedIn) {
          dispatch(
            setLogin({
              user: loggedIn.user,
              token: loggedIn.token,
            })
          );
          navigate("/home");
          // Display success message
          messageApi.success({
            content: "Logged In successfully!",
            key: "LoginMessage",
          });
        }
        // Display success message
        messageApi.success({
          content: "Logged In successfully!",
          key: "LoginMessage",
        });
        setTimeout(messageApi.destroy,2000);

      } else {
        // Error message
        message.error("Login failed. Please check your credentials."); // Close the message after 2 seconds
        setTimeout(messageApi.destroy,2000);
      }
    } catch (error) {
      console.error(error);
      // Error message for unexpected errors
      messageApi.error({ content: "Login Error", key: "LoginMessage" });
      setTimeout(messageApi.destroy,2000);

    } finally {
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      // Call uploadImage to handle file upload
      const imageDownloadURL = await uploadImage();
  
  
      // Update the animals field in the form data
      const formData = new FormData();
     
      formData.append('picturePath', imageDownloadURL);
  
      // Call login or register based on the form type
      if (isLogin) {
        await login(values, onSubmitProps, );
      }
  
      if (isRegister) {
        await register(values, onSubmitProps, imageDownloadURL);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };
  
  

  const [imageUpload, setImageUpload] = useState(null)

  const onDrop = useCallback((acceptedFiles) => {
    // Update state with the first accepted file
    setImageUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    'image/png': ['.png'], 
    'image/jpeg': ['.jpg', '.jpeg'],
        onDrop,
  });
  const uploadImage = () => {
    if (imageUpload == null) return Promise.resolve(null);
  
    const imageRef = ref(storage, `profileImages/${imageUpload.name + v4()}`);
  
    return new Promise((resolve, reject) => {
      // Upload the image
      uploadBytes(imageRef, imageUpload)
        .then(() => {
          // Image uploaded successfully, now get the download URL
          getDownloadURL(imageRef)
            .then((newDownloadURL) => {
              // Resolve the promise with the download URL
              resolve(newDownloadURL);
            })
            .catch((error) => {
              console.error('Error getting download URL:', error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          reject(error);
        });
    });
  };
  




  

  const [selectedState, setSelectedState] = useState("");
  const [selectedLGA, setSelectedLGA] = useState("");

 

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    // Reset selected LGA when state changes
    setSelectedLGA("");
  };

  const handleLGAChange = (event) => {
    setSelectedLGA(event.target.value);
  };
  return (<>

    <Formik
      onSubmit={handleFormSubmit}
      initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
      validationSchema={isLogin ? loginSchema : registerSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isRegister && (
              <>
      
  <div>
      <div {...getRootProps()} style={getDropzoneStyle(isDragActive)}>
        <input {...getInputProps()} />
        {imageUpload ? (
          <p>Selected File: {imageUpload.name}</p>
        ) : (
          <p>
            {isDragActive
              ? 'Drop the image file here...'
              : 'Drag \'n\' drop an image file here, or click to select one'}
          </p>
        )}
      </div>
      <button onClick={uploadImage}>Upload Image</button>
    </div>
                <TextField
                  label="Full Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName || ""}
                  name="firstName"
                  error={
                    Boolean(touched.firstName) && Boolean(errors.firstName)
                  }
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 2" }}
                />

<TextField
                  label="streetname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address || ""}
                  name="address"
                  error={
                    Boolean(touched.address) && Boolean(errors.address)
                  }
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 2" }}
                />

<TextField
  label="Phone Number"
  type="tel" // Specify the input type as "tel" for phone number
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.phoneNumber || ""} // Update the value and name to match "phoneNumber"
  name="phoneNumber"
  error={Boolean(touched.phoneNumber) && Boolean(errors.phoneNumber)} // Update the field names for errors and touched
  helperText={touched.phoneNumber && errors.phoneNumber}
  sx={{ gridColumn: "span 2" }}
/>

<FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="state-label">State</InputLabel>
        <Select
          labelId="state-label"
          id="selectedState"
          value={selectedState}
          onChange={handleStateChange}
        >
          {locations.map((state, index) => (
            <MenuItem key={index} value={state.state}>
              {state.state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="lga-label">LGA</InputLabel>
        <Select
          labelId="lga-label"
          id="selectedLGA"
          value={selectedLGA}
          onChange={handleLGAChange}
          disabled={!selectedState} // Disable if no state selected
        >
          {selectedState &&
            locations.find((state) => state.state === selectedState).lgas.map((lga, index) => (
              <MenuItem key={index} value={lga}>
                {lga}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

    


              </>
              
           
            )}

            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email || ""}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password || ""}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            {contextHolder}

            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: '#F50B82',
                color: palette.background.alt,
                "&:hover": { color: '#F50B82' },
              }}
            >
              {isLogin ? "LOGIN" : "REGISTER"}
            </Button>
            <Typography
              onClick={() => {
                setPageType(isLogin ? "register" : "login");
                resetForm();
              }}
              sx={{
                textDecoration: "underline",
                color: palette.primary.main,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,
                },
              }}
            >
              {isLogin
                ? "Don't have an account? Sign Up here."
                : "Already have an account? Login here."}
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
    </>
  );
};

export default Form;

const getDropzoneStyle = (isDragActive) => ({
  border: `2px dashed ${isDragActive ? 'green' : '#cccccc'}`,
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
});
