import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const ProfessionalCarousel = ({ professionals, CardComponent }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const itemsPerSlide = isLargeScreen ? 5 : 3;

  const groupedProfessionals = professionals.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerSlide);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <Carousel
      animation="slide"
      indicators={true}
      navButtonsAlwaysVisible={true}
      cycleNavigation={true}
      interval={10000} // Changed from default 4000 to 10000 (10 seconds)
      navButtonsProps={{
        style: {
          backgroundColor: '#fff',
          color: '#494949'
        }
      }}
    >
      {groupedProfessionals.map((group, index) => (
        <Grid container spacing={2} key={index}>
          {group.map((professional) => (
            <Grid item xs={12 / itemsPerSlide} key={professional._id}>
                <Link to={`/professional/${professional._id}`} style={{ textDecoration: 'none' }}>
                  <CardComponent professional={professional} />
                </Link>
            </Grid>
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};

export default ProfessionalCarousel;