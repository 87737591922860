import React from 'react';
import './AboutPage.css';

function AboutPage() {
  return (
    <div>
      <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">About</span>
        </div>
        {/* Background Image */}
        <picture className="cs-background">
          {/* Mobile Image */}
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          {/* Tablet and above Image */}
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
     
    </div>
  );
}

export default AboutPage;