import './Footer.css'
import { Link } from 'react-router-dom'
import React from 'react'

function Footer() {
  return (
    <footer id="cs-footer-269">
      <div className="cs-container">
        {/* Logo Group */}
        <div className="cs-logo-group" style={{marginTop:'-10%'}}>
          <a aria-label="go back to home" className="cs-logo" href="http://localhost:3000/about-us">
            <img className="cs-logo-img light" aria-hidden="true" loading="lazy" decoding="async" src="/images/logo_2.png" alt="logo" style={{width:"100%"}} />
          </a>
          <p className="cs-text">
            Compassionate care for every animal. Join us in making a difference.
          </p>
        </div>
        {/* Links */}
        <ul className="cs-nav">
        
          <li className="cs-nav-li">,
            <Link to="/" className="cs-nav-link">Home</Link>
          </li>
          <li className="cs-nav-li">
            <Link to="/about" className="cs-nav-link">About</Link>
          </li>
          <li className="cs-nav-li">
            <Link to="/services" className="cs-nav-link">Services</Link>
          </li>
          <li className="cs-nav-li">
            <Link to="/about-us" className="cs-nav-link">About Us</Link>
          </li>
          <li className="cs-nav-li">
            <Link to="/faq" className="cs-nav-link">FAQ</Link>
          </li>
          <li className="cs-nav-li">
            <Link to="/privacy-policy" className="cs-nav-link">Privacy Policy</Link>
          </li>
          <li className="cs-nav-li">
            <Link to="/terms-and-conditions" className="cs-nav-link">Terms & Conditions</Link>
          </li>
        </ul>
        {/* Contact Info */}
        <ul className="cs-contact">
          <li className="cs-nav-li">
            <span className="cs-header">Contact</span>
          </li>
          <li className="cs-contact-li">
            <a className="cs-contact-link" href="tel:0810-969-0608">0810-969-0608</a>
          </li>
          <li className="cs-contact-li">
            <a className="cs-contact-link" href="mailto:info@havendogs.com">info@havendogs.com</a>
          </li>
          {/* Social Media */}
          <li className="cs-contact-li cs-social-group">
            <div className="cs-social">
              <a className="cs-social-link" aria-label="visit google profile" href="https://www.instagram.com/havenpethome_ng/">
                <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Social/google.svg" alt="google" width="11" height="11" />
              </a>
              <a className="cs-social-link" aria-label="visit facebook profile" href="https://www.instagram.com/havenpethome_ng/">
                <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Social/Facebook.svg" alt="facebook" width="6" height="11" />
              </a>
              <a className="cs-social-link" aria-label="visit instagram profile" href="https://www.instagram.com/havenpethome_ng/">
                <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Social/instagram.svg" alt="instagram" width="11" height="11" />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
