import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { setStats } from "state";
import { useSelector, useDispatch } from "react-redux";
import UserActions from "./UserActions";
import MainLogs from "./mainLogs/MainLogs";
import "./OverallTab.css";


export default function OverallTab({ isDarkMode }) {
  const activeTab = useSelector((state) => state.activeTab);
  const items = useSelector((state) => state.user.items);
  const user = useSelector((state) => state.user);

  const [messageApi, contextHolder] = message.useMessage();

  const replaceUser = async (user) => {
    const bodyData = {
      id: user._id,
      firstName: user.firstName,
      email: user.email,
      items: user.items,
    };

    try {
      // Display loading message
      messageApi.loading({
        content: "Replacing data...",
        key: "replaceUserMessage",
        duration: 0,
      });

      const response = await fetch("https://havendogs.onrender.com/auth/replace", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      // Display success message
      messageApi.success({
        content: "Data replaced successfully!",
        key: "replaceUserMessage",
      });
      setTimeout(messageApi.destroy, 2000);

      // console.log(data);
    } catch (error) {
      // Display error message
      messageApi.error({
        content: "Failed to replace data!",
        key: "replaceUserMessage",
      });
      setTimeout(messageApi.destroy, 2000);
      console.error("Error replacing user:", error);
    }
  };

  const operations = {
    right: (
      <Button className="save-game-button" onClick={() => replaceUser(user)}>
        SAVE GAME
      </Button>
    ),
  };

  return (
    <div>
    <MainLogs/>

    </div>
  );
}
