import React from 'react';
import './IndegenousDogPrograms.css'

const IndigenousDogPrograms = () => {
    return (
        <div className="indigenous-dog-programs">
            <div className="banner-712">
                <div className="cs-container" style={{ textAlign: 'center' }}>
                    <span className="cs-int-title" style={{ textAlign: 'center' }}>Indigenous Dog Programs</span>
                </div>
                <picture className="cs-background">
                    <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
                </picture>
            </div>

            <section className="introduction">
                <h2>Introduction</h2>
                <p>
                    At Haven Pet Home and Animal Care Foundation, we are committed to protecting and caring for indigenous 
                    dog breeds. Our Indigenous Dog Programs aim to rescue, rehabilitate, and rehome these unique and often 
                    overlooked dogs, ensuring they receive the care and respect they deserve.
                </p>
            </section>
            
            <section className="importance">
                <h2>Why Indigenous Dog Programs?</h2>
                <p>
                    Indigenous dogs are an integral part of our heritage and biodiversity. Our programs focus on preserving 
                    these breeds, many of which are at risk due to neglect and lack of awareness. By caring for and promoting 
                    indigenous dogs, we contribute to the preservation of these important breeds and highlight their unique 
                    qualities and adaptability.
                </p>
            </section>
            
            <section className="benefits">
                <h2>Benefits of Our Program</h2>
                <ul>
                    <li>Protects and preserves indigenous dog breeds</li>
                    <li>Provides medical care and rehabilitation for rescued dogs</li>
                    <li>Promotes the adoption of indigenous dogs</li>
                    <li>Raises awareness about the importance of indigenous breeds</li>
                </ul>
            </section>
            
            <section className="how-it-works">
                <h2>How Our Program Works</h2>
                <p>
                    Our Indigenous Dog Programs are designed to address the specific needs of these breeds. We collaborate with 
                    local communities and experts to ensure comprehensive care and support for indigenous dogs. Here’s how our program works:
                </p>
                <ol>
                    <li>Rescue: We actively rescue indigenous dogs from neglect, abuse, and abandonment.</li>
                    <li>Rehabilitation: Each dog receives a thorough health check and a personalized rehabilitation plan.</li>
                    <li>Rehoming: We work to find loving and suitable homes for rehabilitated dogs, ensuring they are matched with the right families.</li>
                    <li>Education: We conduct community outreach programs to raise awareness about the value of indigenous dog breeds and the importance of their preservation.</li>
                </ol>
            </section>
            
            <section className="success-stories">
                <h2>Success Stories</h2>
                <p>
                    Our Indigenous Dog Programs have helped many dogs find new hope and loving homes. Here are some of our success stories:
                </p>
                <ul>
                    <li><strong>Rufus:</strong> An indigenous dog who was rescued from a difficult situation and is now thriving with his new family.</li>
                    <li><strong>Arroow:</strong> A native breed who was rehabilitated and adopted by a family that appreciates her unique qualities and heritage.</li>
                </ul>
            </section>
            
            <section className="get-involved">
                <h2>How You Can Get Involved</h2>
                <p>
                    You can support our Indigenous Dog Programs by:
                </p>
                <ul>
                    <li>Donating to help fund rescue and rehabilitation efforts</li>
                    <li>Volunteering your time to assist with community outreach and dog care</li>
                    <li>Adopting an indigenous dog and giving them a loving home</li>
                    <li>Spreading the word about the importance of preserving indigenous dog breeds</li>
                </ul>
                <p>
                    Together, we can make a difference in the lives of indigenous dogs and ensure they receive the care and respect they deserve.
                </p>
            </section>
            
            <footer className="footer">
                <p>
                    Haven Pet Home and Animal Care Foundation is committed to the preservation and well-being of indigenous dog breeds 
                    through our Indigenous Dog Programs. Join us in our mission to rescue, rehabilitate, and find new homes for animals in need.
                </p>
                <p>Contact us at: <a href="mailto:info@havendogs.org">info@havendogs.org</a></p>
            </footer>
        </div>
    );
};

export default IndigenousDogPrograms;
