import React, { useState, useEffect } from 'react';
import './Ticket.css'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
const Ticket = () => {
    const [tickets, setTickets] = useState([]);
    const [filter, setFilter] = useState('all'); // Default filter to display all tickets
  
    useEffect(() => {
      fetchTickets();
    }, [filter]); // Trigger fetchTickets when filter changes


  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await fetch('https://havendogs.onrender.com/tickets/tickets');
      if (!response.ok) {
        throw new Error('Failed to fetch tickets');
      }
      const data = await response.json();
      setTickets(data);
      console.log(data);
      return data; // Return data instead of setting state directly
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw error; // Re-throw error to handle it outside
    }
  };
  
  const createTicket = async (ticket) => {
    try {
      const response = await fetch('https://havendogs.onrender.com/tickets/tickets', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ticket)
      });
      if (!response.ok) {
        throw new Error('Failed to create ticket');
      }
      return await response.json(); // Return response data
    } catch (error) {
      console.error('Error creating ticket:', error);
      throw error; // Re-throw error to handle it outside
    }
  };
  
  const deleteTicket = async (id) => {
    try {
      const response = await fetch(`https://havendogs.onrender.com/tickets/tickets/${id}`, { 
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error('Failed to delete ticket');
      }
      fetchTickets(); // Refetch tickets after successful deletion

      return true; // Indicate successful deletion
    } catch (error) {
      console.error('Error deleting ticket:', error);
      throw error; // Re-throw error to handle it outside
    }
  };

  const updateTicketStatus = async (id, status) => {
    try {
      const response = await fetch(`https://havendogs.onrender.com/tickets/tickets/${id}`, { 
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status })
      });
      if (!response.ok) {
        throw new Error('Failed to update ticket status');
      }
      fetchTickets(); // Refresh tickets after successful update
    } catch (error) {
      console.error('Error updating ticket status:', error);
      throw error;
    }
  };

  const handleStatusChange = async (id, e) => {
    const newStatus = e.target.value;
    await updateTicketStatus(id, newStatus);
  };

  const filteredTickets = filter === 'all' ? tickets : tickets.filter(ticket => ticket.status === filter);

  // Count the number of tickets in each status
  const openTickets = tickets.filter(ticket => ticket.status === 'open').length;
  const pendingTickets = tickets.filter(ticket => ticket.status === 'pending').length;
  const closedTickets = tickets.filter(ticket => ticket.status === 'closed').length;

  return (
    <div>
    <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Tickets</h2>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  marginBottom: '10px' }}>
      <button style={{ marginRight: '5px' }} className={filter === 'all' ? 'active' : ''} onClick={() => setFilter('all')}>All</button>
      <button style={{ marginRight: '5px' }} className={filter === 'open' ? 'active' : ''} onClick={() => setFilter('open')}>Open ({openTickets})</button>
      <button style={{ marginRight: '5px' }} className={filter === 'pending' ? 'active' : ''} onClick={() => setFilter('pending')}>Pending ({pendingTickets})</button>
      <button className={filter === 'closed' ? 'active' : ''} onClick={() => setFilter('closed')}>Closed ({closedTickets})</button>
    </div>

    <Grid container spacing={2}>
      {filteredTickets.map((ticket) => (
        <Grid item key={ticket._id} xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Description: {ticket.type}
              </Typography>
              <Typography variant="body1">
                Status: 
                <select value={ticket.status} onChange={(e) => handleStatusChange(ticket._id, e)}>
                  <option value="open">Open</option>
                  <option value="pending">Pending</option>
                  <option value="closed">Closed</option>
                </select>
              </Typography>
              <Typography variant="body1">Service Requested: {ticket.tags ? ticket.tags.join(', ') : 'N/A'}</Typography>
              <Typography variant="body1">Username: {ticket.user ? `${ticket.user.firstName}` : 'N/A'}</Typography>
              <Typography variant="body1">User Phone.: {ticket.user ? `${ticket.user.phoneNumber}` : 'N/A'}</Typography>
              <Link 
  key={ticket.professional ? ticket.professional._id : 'default-key'} 
  to={ticket.professional ? `/professional/${ticket.professional._id}` : '#'}
>
  <Typography variant="body1">
    Professional: {ticket.professional ? `${ticket.professional.firstName} ${ticket.professional.lastName}` : 'N/A'}
  </Typography>
</Link>
  <Typography variant="body1">Animals involved: 
              {ticket.animals && ticket.animals.length > 0 ? (
                ticket.animals.map((animal) => (
                  <Link key={animal._id} to={`/my-pet/${animal._id}`}>
                    {animal.name}
                  </Link>
                )).reduce((prev, curr) => [prev, ', ', curr])
              ) : 'None'}
            </Typography>
              <Typography variant="body1">Time Created: {ticket.timeCreated ? new Date(ticket.timeCreated).toLocaleString() : 'N/A'}</Typography>
              {/* Add more relevant fields here */}
              <Button variant="contained" color="error" onClick={() => deleteTicket(ticket._id)}>Delete</Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>


  </div>
  
  );
};

export default Ticket;
