import React, { useEffect, useRef, useState } from 'react';

const Article1 = () => {
  const imagesRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      imagesRef.current.forEach((imageRef) => {
        if (imageRef) {
          const slideInAt = window.scrollY + window.innerHeight - imageRef.offsetHeight / 2;
          const imageBottom = imageRef.offsetTop + imageRef.offsetHeight;
          const isHalfShown = slideInAt > imageRef.offsetTop;
          const isNotScrolledPast = window.scrollY < imageBottom;

          if (isHalfShown && isNotScrolledPast) {
            imageRef.classList.add('active');
          } else {
            imageRef.classList.remove('active');
          }
        }
      });
    };

    const debounce = (func, wait = 20, immediate = true) => {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        const later = () => {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    const handleScrollDebounced = debounce(handleScroll);
    window.addEventListener('scroll', handleScrollDebounced);

    return () => {
      window.removeEventListener('scroll', handleScrollDebounced);
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !imagesRef.current.includes(el)) {
      imagesRef.current.push(el);
    }
  };

  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const winTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;
      const scrolled = (winTop / (docHeight - winHeight)) * 100;
      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
    <div className="banner-712">
    <div className="cs-container">
      <span className="cs-int-title">Things You Should Know About Animal Rescuing</span>
    </div>
    <div className="scroll-line" style={{ width: `${scrollPercentage}%` }}></div>

    {/* Background Image */}
    <picture className="cs-background">
      {/* Mobile Image */}
      <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
      {/* Tablet and above Image */}
      <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
      <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
    </picture>
  </div>
    <div className="site-wrap">

      <p>
        Animal rescuing is a noble and fulfilling endeavor that involves saving animals from harmful situations and providing them with the care they need. Here are some key things you should know about animal rescuing.
      </p>
     
      <img
        src="http://unsplash.it/400/400"
        className="align-left slide-in"
        ref={addToRefs}
        alt="Slide in on scroll"
      />

      <p>
        Animal rescuing can be emotionally challenging but incredibly rewarding. You might encounter animals that have been abused, abandoned, or are suffering from health issues. Being prepared for these situations is crucial. Rescuers often work closely with veterinarians to ensure animals receive medical attention, vaccinations, and are spayed or neutered. Additionally, providing rehabilitation and socialization for rescued animals is vital to help them adapt to new, loving homes.
      </p>

      <img
        src="http://unsplash.it/400/401"
        className="align-right slide-in"
        ref={addToRefs}
        alt="Slide in on scroll"
      />

      <p>
        Volunteering with animal rescue organizations is a great way to contribute. You can assist with daily care, foster animals, help with administrative tasks, or participate in fundraising events. Many rescues rely heavily on donations and volunteers to operate. Fostering is especially impactful, as it provides a temporary home for animals while they await adoption, reducing the burden on shelters and giving animals a better environment to thrive.
      </p>

      <img
        src="http://unsplash.it/200/500"
        className="align-left slide-in"
        ref={addToRefs}
        alt="Slide in on scroll"
      />

      <p>
        If you're considering starting your own rescue, it's important to understand the legal and financial aspects. Rescues must comply with local regulations, which may include obtaining licenses, adhering to animal welfare laws, and meeting facility standards. Funding is another critical component; many rescues apply for grants, hold fundraising events, and seek donations to cover the costs of veterinary care, food, and other essentials.
      </p>

      <img
        src="http://unsplash.it/200/200"
        className="align-right slide-in"
        ref={addToRefs}
        alt="Slide in on scroll"
      /> 

      <p>
        Networking with other animal welfare organizations can enhance your rescue efforts. Collaborating with shelters, veterinarians, and other rescue groups can provide additional resources, knowledge, and support. It's also beneficial to engage with the community through education and outreach programs to raise awareness about animal welfare and promote responsible pet ownership.
      </p>

      <p>
        Animal rescuing requires dedication, compassion, and resilience. It's not just about saving animals; it's about improving their lives and finding them permanent, loving homes. By understanding the challenges and rewards of animal rescuing, you can make a significant impact on the lives of animals in need.
      </p>
     
   </div>
</>
 );
};

export default Article1;