import React, { useEffect, useRef, useState } from 'react';
import './Article5.css'
const Article5 = () => {
  const imagesRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      imagesRef.current.forEach((imageRef) => {
        if (imageRef) {
          const slideInAt = window.scrollY + window.innerHeight - imageRef.offsetHeight / 2;
          const imageBottom = imageRef.offsetTop + imageRef.offsetHeight;
          const isHalfShown = slideInAt > imageRef.offsetTop;
          const isNotScrolledPast = window.scrollY < imageBottom;

          if (isHalfShown && isNotScrolledPast) {
            imageRef.classList.add('active');
          } else {
            imageRef.classList.remove('active');
          }
        }
      });
    };

    const debounce = (func, wait = 20, immediate = true) => {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        const later = () => {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    const handleScrollDebounced = debounce(handleScroll);
    window.addEventListener('scroll', handleScrollDebounced);

    return () => {
      window.removeEventListener('scroll', handleScrollDebounced);
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !imagesRef.current.includes(el)) {
      imagesRef.current.push(el);
    }
  };

  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const winTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;
      const scrolled = (winTop / (docHeight - winHeight)) * 100;
      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">Statement from Haven Pet and Animal Care Foundation</span>
        </div>
        <div className="scroll-line" style={{ width: `${scrollPercentage}%` }}></div>

        <picture className="cs-background">
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img 
            loading="lazy" 
            decoding="async" 
            src="/images/pinnock.jpg" 
            alt="Animal care background" 
            width="1280" 
            height="568" 
            aria-hidden="true" 
          />
        </picture>
      </div>

   
      <div className="site-wrap">
      <picture className="cs-picture">
              <source media="(max-width: 600px)" srcSet="/images/pinnock.jpg" />
              <source media="(min-width: 601px)" srcSet="/images/pinnock.jpg" />
              <img loading="lazy" decoding="async" alt='headline in pink with the header written in white ' src="/images/pinnock.jpg" />
            </picture>

        <p>
          The Haven Pet and Animal Care Foundation has recently addressed a tragic incident that occurred at Pinnock Estate, Lekki, involving two dogs and the unfortunate loss of a human life.
        </p>

      
        <p>
          As the incident gained widespread attention on social media, the Foundation emphasizes the importance of allowing proper investigation before drawing conclusions. They note that the circulating video shows no visible evidence of blood on the dogs' mouths or torn clothing on the individual involved, highlighting the need for a thorough investigation to determine the true sequence of events.
        </p>


        <p>
          The Foundation has expressed their deepest sympathies to the deceased's family during this difficult time. While acknowledging the tragic nature of the incident, they stress the importance of avoiding premature judgments against the dogs until all facts are established through proper investigation.
        </p>

        <p>
          At its core, Haven Pet and Animal Care Foundation stands for principles of compassion, fairness, and responsible pet ownership. They urge the public to allow law enforcement to complete their investigation before making definitive claims about the case.
        </p>


        <p>
          The Foundation's approach demonstrates their commitment to both human safety and animal welfare, emphasizing the need for a balanced and thorough investigation of the incident. They continue to advocate for responsible pet ownership while showing respect and empathy for all parties involved in this tragic situation.
        </p>

        <p>
          As the investigation continues, the Foundation maintains its position of supporting both the grieving family and the proper legal process, exemplifying their dedication to truth, fairness, and the wellbeing of both humans and animals in their community.
        </p>
      </div>
    </>
  );
};

export default Article5;