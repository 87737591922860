// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage}  from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWMo42ojPJ1Vgoa5Ee1FWhbYTFWSP2Q8E",
  authDomain: "fahida.firebaseapp.com",
  projectId: "fahida",
  storageBucket: "fahida.appspot.com",
  messagingSenderId: "193959043068",
  appId: "1:193959043068:web:c1a6f9df248863c1c81c79",
  measurementId: "G-M3WHXT29ZQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app)