import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Article.css';

function Article2() {

  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const winTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;
      const scrolled = (winTop / (docHeight - winHeight)) * 100;
      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div>
      <div className="banner-712">
        <div className="cs-container">
          <span className="cs-int-title">Promoting Responsible Animal Care and Breeding: What You Need to Know</span>
        </div>
        <div className="scroll-line" style={{ width: `${scrollPercentage}%` }}></div>

        {/* Background Image */}
        <picture className="cs-background">
          {/* Mobile Image */}
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          {/* Tablet and above Image */}
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
      
      <section id="content-page-852">
  <div className="cs-container">
    <div className="cs-content">
      <h1 className="cs-title">
        Promoting Responsible Animal Care and Breeding: What You Need to Know
      </h1>
      <h2>Understanding the Importance of Responsible Animal Care</h2>
      <h3>The Role of Responsible Breeding in Animal Welfare</h3>
      <h4>Ensuring Ethical Practices</h4>
      <p>
        Promoting responsible animal care and breeding is crucial for the well-being of pets and livestock alike. Responsible breeding practices help prevent genetic disorders, reduce the number of abandoned animals, and ensure that animals are raised in healthy, humane environments. By understanding the principles of responsible breeding and animal care, pet owners and breeders can contribute to the overall welfare of animals.
      </p>
      <p>
        To start with, responsible breeders prioritize the health and well-being of their animals. They ensure that breeding pairs are healthy and genetically compatible, minimizing the risk of hereditary diseases. This approach not only improves the quality of life for the animals but also provides peace of mind for future pet owners.
      </p>
      <h3>Key Principles of Responsible Animal Breeding</h3>
      <ol>
        <li>Health Screening: Conducting comprehensive health checks to identify and address potential genetic issues.</li>
        <li>Ethical Breeding Practices: Avoiding overbreeding and ensuring that animals are bred in humane conditions.</li>
        <li>Education and Support: Providing new pet owners with the necessary information and resources to care for their animals properly.</li>
      </ol>
      <h4>Steps to Promote Responsible Animal Care</h4>
      <ul>
        <li>Regular Veterinary Visits: Ensuring that animals receive regular health check-ups and vaccinations.</li>
        <li>Proper Nutrition: Providing a balanced diet tailored to the specific needs of the animal.</li>
        <li>Exercise and Enrichment: Offering opportunities for physical activity and mental stimulation to keep animals healthy and happy.</li>
      </ul>
    </div>
    <div className="cs-image-group">
      <picture className="cs-picture">
        {/* Mobile Image */}
        <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256701/pexels-photo-5256701.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
        {/* Tablet and above Image */}
        <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256701/pexels-photo-5256701.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
        <img
          decoding="async"
          src="https://images.pexels.com/photos/5256701/pexels-photo-5256701.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="happy animals"
          width="542"
          height="520"
          aria-hidden="true"
        />
      </picture>
    </div>
  </div>
</section>

    </div>
  );
}

export default Article2;
