import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import './CatRescue.css';

const CatRescueArticle = () => {
  return (
    <article className="rescue-article">
      <header className="article-header">
        <h1 className="article-title">Feline Freedom: The Tale of Our Multi-Cat Rescue</h1>
        <div className="author-info">
          <img 
            src="/api/placeholder/100/100" 
            alt="/Whiskers" 
            className="author-image"
          />
          <div>
            <p className="author-name">Sarah Adenike</p>
            <p className="author-role">Cat Rescue Coordinator | July 25, 2024</p>
          </div>
        </div>
        <img 
          src="/api/placeholder/1200/600" 
          alt="Group of rescued cats" 
          className="header-image"
        />
      </header>

      <section className="article-section">
        <h2 className="section-title">The Desperate Situation</h2>
        <p>
          It was a typical Tuesday morning when we received an alarming call about a hoarding situation gone wrong. The caller described a small apartment overflowing with cats - some friendly, some feral, all in desperate need of help. We knew we had to act quickly.
        </p>
        <p>
          Our team arrived at the scene to find over 30 cats living in deplorable conditions. The air was thick with the smell of ammonia, and many of the cats showed signs of malnutrition and untreated medical issues. It was clear that this rescue operation would be one of our most challenging yet.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">The Rescue Operation</h2>
        <p>
          Rescuing such a large number of cats requires careful planning and execution. Our team, consisting of experienced handlers, veterinarians, and volunteers, worked tirelessly to safely capture and transport each cat.
        </p>
        <img 
          src="/api/placeholder/800/500" 
          alt="Rescue team carefully handling cats" 
          className="section-image"
        />
        <p>
          The process was slow and meticulous. We set up humane traps for the more skittish cats and used gentle handling techniques for those who were friendlier. Each cat was given a brief health check before being placed in a secure carrier for transport to our shelter.
        </p>
      </section>

      <Alert severity="warning" className="rescue-alert">
        <AlertTitle>Important Note on Cat Hoarding Situations</AlertTitle>
        Never attempt to handle a large-scale animal rescue on your own. These situations can be dangerous for both humans and animals. Always involve professional rescuers and animal control officers to ensure everyone's safety.
      </Alert>

      {/* ... rest of the sections ... */}

      <footer className="article-footer">
        <p>
          If you suspect a case of animal hoarding or neglect, please contact your local animal control or humane society immediately. Your action could save lives and prevent suffering.
        </p>
      </footer>
    </article>
  );
};

export default CatRescueArticle;