import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import './HorseOwnershipTipsArticle.css'
const HorseOwnershipTipsArticle = () => {
  return (
    <article className="rescue-article">
      <header className="article-header">
        <h1 className="article-title">Top Tips for First-Time Horse Owners in Nigeria</h1>
        <div className="author-info">
          <img 
            src="/api/placeholder/100/100" 
            alt="Author" 
            className="author-image"
          />
          <div>
            <p className="author-name">Haven Dogs Team</p>
            <p className="author-role">Pet Care Experts | July 23, 2024</p>
          </div>
        </div>
        <img 
          src="/images/horses.jpg" 
          alt="Happy horse in a lush field" 
          className="header-image"
        />
      </header>

      <section className="article-section">
        <p>
          Welcoming a horse into your life is an incredibly rewarding experience, filled with joy and companionship. However, it also comes with responsibilities to ensure your new equine friend thrives. Whether you're a first-time horse owner or looking to improve your horse care routine, these top tips will help you provide the best care for your horse.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">1. Proper Nutrition</h2>
        <p>
          Horses require a balanced diet rich in forage, such as hay or grass, which is crucial for their digestive health. Supplement their diet with grains and specialized horse feeds as needed. Always provide fresh, clean water, and consult a veterinarian to develop a nutrition plan tailored to your horse's specific needs.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">2. Comfortable Shelter</h2>
        <p>
          Providing a safe and comfortable shelter is essential for your horse's well-being. A stable or barn that offers protection from the elements, adequate space for movement, and proper ventilation is crucial. Ensure the shelter is clean, dry, and equipped with comfortable bedding like straw or wood shavings.
        </p>
        <img 
          src="/images/horse-stable.jpg" 
          alt="Comfortable horse stable" 
          className="section-image"
        />
      </section>

      <section className="article-section">
        <h2 className="section-title">3. Regular Exercise</h2>
        <p>
          Horses need regular exercise to stay healthy and happy. Ensure your horse has ample opportunities to move and stretch their legs. Turnout time in a secure, enclosed pasture is ideal. Engage in activities such as riding, lunging, or ground work to keep your horse physically fit and mentally stimulated.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">4. Routine Health Checks</h2>
        <p>
          Regular veterinary check-ups are vital to monitor your horse's health and catch potential issues early. Schedule vaccinations, deworming, and dental care as recommended by your vet. Keep an eye on your horse's weight, coat condition, and overall demeanor to detect any signs of illness.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">5. Hoof Care</h2>
        <p>
          Proper hoof care is crucial for a horse's overall health. Schedule regular visits from a qualified farrier to trim and inspect your horse's hooves. Clean their hooves daily to prevent infections and ensure they remain in good condition.
        </p>
        <img 
          src="/api/placeholder/800/500" 
          alt="Farrier caring for horse's hooves" 
          className="section-image"
        />
      </section>

      <section className="article-section">
        <h2 className="section-title">6. Grooming</h2>
        <p>
          Grooming not only keeps your horse clean but also helps build a bond between you and your equine companion. Brush their coat, mane, and tail regularly to remove dirt and debris. Check for any cuts, bumps, or signs of irritation, and groom daily to maintain a healthy coat and skin.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">7. Safe Environment</h2>
        <p>
          Ensure your horse's environment is safe and free from hazards. Secure fencing, free from sharp edges, is essential. Remove any toxic plants from the pasture and store feed and equipment in a safe, dry place to prevent contamination.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">8. Training and Socialization</h2>
        <p>
          Proper training and socialization are vital for a well-behaved horse. Start with basic commands and gradually introduce more advanced training. Spend time with your horse daily to build trust and strengthen your bond. Socialization with other horses can also help your horse develop good manners and reduce stress.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">9. Mental Stimulation</h2>
        <p>
          Horses are intelligent animals that thrive on mental stimulation. Provide your horse with toys, puzzles, and varied activities to keep them engaged. Change up their routine to prevent boredom and encourage curiosity.
        </p>
      </section>

      <section className="article-section">
        <h2 className="section-title">10. Love and Attention</h2>
        <p>
          Finally, shower your horse with love and attention. Building a strong, positive relationship with your horse is the foundation of effective care. Spend quality time together, showing affection and patience as they adjust to their new home.
        </p>
        <img 
          src="/images/horse-bond.jpg" 
          alt="Owner bonding with horse" 
          className="section-image"
        />
      </section>

      <Alert severity="info" className="rescue-alert">
        <AlertTitle>Important Note for New Horse Owners</AlertTitle>
        Building a strong, positive relationship with your horse is the foundation of effective care. Spend quality time together, showing affection and patience as they adjust to their new home.
      </Alert>

      <section className="article-section">
        <h2 className="section-title">Conclusion</h2>
        <p>
          Welcoming a horse into your life is a journey filled with learning and growth. By following these top tips, you can ensure your horse enjoys a healthy, happy, and fulfilling life. If you have any questions or need further guidance, feel free to reach out to us at Haven Dogs.
        </p>
      </section>

      <footer className="article-footer">
        <p>
          Visit us at Haven Dogs for more information and resources on pet and animal care.
        </p>
      </footer>
    </article>
  );
};

export default HorseOwnershipTipsArticle;