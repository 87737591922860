import React from 'react';
import './StreetDog.css'

const StreetDogProgram = () => {
    return (
        <div className="street-dog-program">
            <div className="banner-712">
                <div className="cs-container" style={{ textAlign: 'center' }}>
                    <span className="cs-int-title" style={{ textAlign: 'center' }}>Street Dog Program</span>
                </div>
                <picture className="cs-background">
                    <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
                </picture>
            </div>

            <section className="introduction">
                <h2>Introduction</h2>
                <p>
                    At Haven Pet Home and Animal Care Foundation, our Street Dog Program is dedicated to improving the lives of street dogs. 
                    These dogs face numerous challenges, from lack of food and shelter to exposure to diseases and injuries. 
                    Our program aims to provide the necessary care and support to ensure these dogs have a better quality of life.
                </p>
            </section>
            
            <section className="importance">
                <h2>Why Street Dog Program?</h2>
                <p>
                    Street dogs are often vulnerable and neglected, yet they deserve care and compassion just as much as any other animal. 
                    Our Street Dog Program focuses on addressing the immediate needs of these dogs, including food, medical care, and shelter. 
                    By doing so, we can help reduce the suffering and improve the well-being of street dogs in our community.
                </p>
            </section>
            
            <section className="benefits">
                <h2>Benefits of Our Program</h2>
                <ul>
                    <li>Provides food and clean water for street dogs</li>
                    <li>Offers medical care and vaccinations</li>
                    <li>Helps in rehabilitating injured or sick dogs</li>
                    <li>Promotes the adoption and fostering of street dogs</li>
                    <li>Raises awareness about the plight of street dogs and encourages community involvement</li>
                </ul>
            </section>
            
            <section className="how-it-works">
                <h2>How Our Program Works</h2>
                <p>
                    Our Street Dog Program operates through a network of dedicated volunteers and partnerships with local veterinarians. 
                    Here’s how our program works:
                </p>
                <ol>
                    <li>Rescue: We identify and rescue street dogs in need of immediate help.</li>
                    <li>Care: Each dog receives a thorough health check and necessary medical treatments, including vaccinations.</li>
                    <li>Rehabilitation: We provide food, shelter, and care to rehabilitate dogs and prepare them for adoption or foster care.</li>
                    <li>Adoption: We actively promote the adoption of street dogs, matching them with loving homes.</li>
                    <li>Community Engagement: We conduct outreach programs to educate the community about street dog welfare and encourage involvement.</li>
                </ol>
            </section>
            
            <section className="success-stories">
                <h2>Success Stories</h2>
                <p>
                    Our Street Dog Program has transformed the lives of many dogs. Here are a few of our success stories:
                </p>
                <ul>
                    <li><strong>Lucky:</strong> A street dog who was found injured and nursed back to health. He now lives happily with his new family.</li>
                    <li><strong>Bella:</strong> A stray who was rescued from the streets and rehabilitated. She was adopted by a loving couple who adore her.</li>
                </ul>
            </section>
            
            <section className="get-involved">
                <h2>How You Can Get Involved</h2>
                <p>
                    You can support our Street Dog Program by:
                </p>
                <ul>
                    <li>Donating to help cover the costs of food, medical care, and shelter</li>
                    <li>Volunteering your time to assist with rescues and care</li>
                    <li>Fostering a street dog until a permanent home is found</li>
                    <li>Adopting a street dog and providing them with a loving home</li>
                    <li>Spreading awareness about the challenges street dogs face and the work we do to help them</li>
                </ul>
                <p>
                    Together, we can make a significant difference in the lives of street dogs and ensure they receive the care and compassion they deserve.
                </p>
            </section>
            
            <footer className="footer">
                <p>
                    Haven Pet Home and Animal Care Foundation is committed to rescuing, rehabilitating, and rehoming street dogs through our Street Dog Program. 
                    Join us in our mission to improve the lives of animals in need.
                </p>
                <p>Contact us at: <a href="mailto:info@havendogs.org">info@havendogs.org</a></p>
            </footer>
        </div>
    );
};

export default StreetDogProgram;
