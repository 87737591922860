import React from 'react';
import './SeniorPrograms.css'


const SeniorPrograms = () => {
    return (
        <div className="senior-programs">
           <div className="banner-712">
        <div className="cs-container" style={{textAlign:'center'}}>
          <span className="cs-int-title" style={{textAlign:'center'}}>Senior Programs</span>
        </div>
        <picture className="cs-background">
          <source media="(max-width: 600px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <source media="(min-width: 601px)" srcSet="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <img loading="lazy" decoding="async" src="https://images.pexels.com/photos/5256176/pexels-photo-5256176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pills" width="1280" height="568" aria-hidden="true" />
        </picture>
      </div>
            
            <section className="introduction">
                <h2>Introduction</h2>
                <p>
                    At Haven Pet Home and Animal Care Foundation, we believe that every animal deserves a loving home, 
                    regardless of their age. Our Senior Programs are designed to provide special care and attention to 
                    senior pets, ensuring they live their golden years in comfort and dignity.
                </p>
            </section>
            
            <section className="importance">
                <h2>Why Senior Programs?</h2>
                <p>
                    Senior pets are often overlooked in shelters, yet they have so much love to give. Our Senior Programs 
                    focus on the unique needs of older animals, from medical care to providing a nurturing environment, 
                    helping them thrive in their later years.
                </p>
            </section>
            
            <section className="benefits">
                <h2>Benefits of Our Program</h2>
                <ul>
                    <li>Provides specialized medical care and attention</li>
                    <li>Offers a comfortable and safe environment for senior pets</li>
                    <li>Encourages adoption of older animals</li>
                    <li>Improves quality of life and well-being for senior pets</li>
                </ul>
            </section>
            
            <section className="how-it-works">
                <h2>How Our Program Works</h2>
                <p>
                    Our Senior Programs are designed to cater to the specific needs of older pets. We work closely with 
                    veterinarians and caregivers to ensure each senior pet receives the best care possible. Here’s how our program works:
                </p>
                <ol>
                    <li>Assessment: Each senior pet undergoes a thorough health and behavior assessment.</li>
                    <li>Care Plan: We create a personalized care plan, including medical treatments, dietary needs, and comfort measures.</li>
                    <li>Adoption: We promote the adoption of senior pets through various channels, highlighting their unique qualities and the joy they can bring to a home.</li>
                    <li>Foster Care: For those not yet adopted, we provide loving foster homes where they can live in comfort and receive the care they need.</li>
                </ol>
            </section>
            
            <section className="success-stories">
                <h2>Success Stories</h2>
                <p>
                    Our Senior Programs have positively impacted the lives of many older animals. Here are a few success stories:
                </p>
                <ul>
                    <li><strong>Max:</strong> A senior dog who found a loving home and now enjoys his days lounging in the sun and playing with his new family.</li>
                    <li><strong>Mittens:</strong> An older cat who was provided with the medical care she needed and is now living happily with her new adoptive parents.</li>
                </ul>
            </section>
            
            <section className="get-involved">
                <h2>How You Can Get Involved</h2>
                <p>
                    You can support our Senior Programs by:
                </p>
                <ul>
                    <li>Donating to help cover medical and care expenses for senior pets</li>
                    <li>Volunteering your time to assist with senior pet care</li>
                    <li>Spreading the word about the importance of adopting senior pets</li>
                </ul>
                <p>
                    Together, we can make a difference in the lives of senior animals, ensuring they receive the love and care they deserve.
                </p>
            </section>
            
            <footer className="footer">
                <p>
                    Haven Pet Home and Animal Care Foundation is committed to providing compassionate care for senior pets 
                    through our Senior Programs. Join us in our mission to rescue, rehabilitate, and find new homes for animals in need.
                </p>
                <p>Contact us at: <a href="mailto:info@havendogs.org">info@havendogs.org</a></p>
            </footer>
        </div>
    );
};

export default SeniorPrograms;
