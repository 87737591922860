import React from 'react';
import './ProfessionalCards.css';

export const AnimalProfessionalCard = ({ professional }) => {
  if (!professional || Object.keys(professional).length === 0) {
    return (
      <div className="card animal-card">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="card animal-card">
      <img 
        src={professional.picturePath} 
        alt="Professional Avatar" 
      />
      <h3>{professional.firstName}</h3>
      <p>{professional.lastName}</p>
    </div>
  );
};

export const WalkerProfessionalCard = ({ professional }) => {
  if (!professional || Object.keys(professional).length === 0) {
    return (
      <div className="card walker-card">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="card walker-card">
      <img 
        src={professional.picturePath} 
        alt="Professional Avatar" 
      />
      <h3>{professional.firstName} {professional.lastName}</h3>
      <p>{professional.profession}</p>
      <p>{professional.address}, {professional.lga}, {professional.state}</p>
      <div className="free-label">FREE</div>
    </div>
  );
};

export const SitterProfessionalCard = ({ professional }) => {
  if (!professional || Object.keys(professional).length === 0) {
    return (
      <div className="card sitter-card">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="card sitter-card">
      <img 
        src={professional.picturePath} 
        alt="Professional Avatar" 
      />
      <h3>{professional.firstName} {professional.lastName}</h3>
      <p>{professional.profession}</p>
      <p>{professional.address}, {professional.lga}, {professional.state}</p>
      <div className="free-label">FREE</div>
    </div>
  );
};

export const VetProfessionalCard = ({ professional }) => {
  if (!professional || Object.keys(professional).length === 0) {
    return (
      <div className="card vet-card">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="card vet-card">
      <img 
        src={professional.picturePath} 
        alt="Professional Avatar" 
      />
      <h3>{professional.firstName} {professional.lastName}</h3>
      <p>{professional.profession}</p>
      <p>{professional.verificationNumber}</p>
      <p>{professional.address}, {professional.lga}, {professional.state}</p>
      <div className="free-label">FREE</div>
    </div>
  );
};

export const TrainerProfessionalCard = ({ professional }) => {
  if (!professional || Object.keys(professional).length === 0) {
    return (
      <div className="card trainer-card">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="card trainer-card">
      <img 
        src={professional.picturePath} 
        alt="Professional Avatar" 
      />
      <h3>{professional.firstName} {professional.lastName}</h3>
      <p>{professional.profession}</p>
      <p>{professional.address}, {professional.lga}, {professional.state}</p>
      <div className="free-label">FREE</div>
    </div>
  );
};

export const ClinicProfessionalCard = ({ professional }) => {
  if (!professional || Object.keys(professional).length === 0) {
    return (
      <div className="card clinic-card">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="card clinic-card">
      <img 
        src={professional.picturePath} 
        alt="Professional Avatar" 
      />
      <h3>{professional.firstName} {professional.lastName}</h3>
      <p>{professional.verificationNumber}</p>
      <p>{professional.address}, {professional.lga}, {professional.state}</p>
      <div className="free-label">FREE</div>
    </div>
  );
};